<template>
  <div class="setMealBox">
    <div class="setMealList" v-for="(item, index) in setMealConfig.list" :key="index">
      <div class="imgBox">
        <img :src="item.cover_picture" alt="">
      </div>
      <div class="setMealInfo">
        <div class="top">
          <div class="title">
            <span>{{ setMealConfig.tag_txt }}</span>{{ item.package_name }}
          </div>
          <div class="preferential" v-if="item.type == 1">{{ getPreferential(item) }}</div>
        </div>
        <div class="footer">
          <div class="price">{{ getPrice(item) }}</div>
          <div class="btn" :style="{ backgroundColor: setMealConfig.colorSystem }">{{ setMealConfig.add_txt }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    setMealConfig: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {
    getPreferential (data) {
      let result = `预计可省￥0~￥0`
      if (data.goods_data) {
        let goods = JSON.parse(data.goods_data) || []
        let sum = 0
        goods.map(item => {
          sum += parseFloat(item.goods_price)
        })
        result = `预计可省￥${sum - data.package_price}`
      }
      return result
    },
    getPrice (data) {
      let price = `￥${data.package_price}` || '0.00'
      if (data.type == 2) price = `￥${data.min_matching_price}`
      return price
    }
  },
};
</script>

<style lang="less" scoped>
.setMealBox {
  width: 100%;
  padding: 0 14px 0 14px;

  .setMealList {
    width: 100%;
    border: 1px solid #FFEDDC;
    border-radius: 10px;
    padding: 15px;
    display: flex;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .imgBox {
    width: 110px;
    height: 100px;
    overflow: hidden;
    border-radius: 10px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .setMealInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top {}

    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin-bottom: 5px;

      span {
        display: inline-block;
        padding: 3px 6px;
        background: linear-gradient(84deg, #FF9760 0%, #FF6764 100%);
        border-radius: 8px 0px 8px 0px;
        color: #fff;
        font-size: 12px;
        font-weight: normal;
        transform: scale(0.83);
      }
    }

    .preferential {
      display: inline-block;
      padding: 3px 8px;
      background: #FDF5F3;
      border-radius: 5px 5px 5px 5px;
      font-size: 11px;
      font-weight: 400;
      color: #FF643B;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        font-size: 20px;
        font-weight: bold;
        color: #FF4B51;
      }

      .btn {
        padding: 5px 12px;
        white-space: nowrap;
        border-radius: 15px;
        color: #fff;
      }
    }
  }
}
</style>