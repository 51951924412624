<template>
  <div class="searchBox" :style="{ background: searchBox.color }">
    <div class="positon" v-if="searchBox.isPositon">
      <img id="positionIcon" :src="imgH + 'positionIcon.png'" alt="">
      <div>城市名</div>
    </div>
    <div class="search"
      :style="{ backgroundColor: searchBox.searchBg, borderColor: searchBox.searchBorder, color: searchBox.fontColor }">
      <i :style="{ color: searchBox.fontColor }" class="iconfont icon-sousuo"></i>
      <p>{{ searchBox.tipsTxt }}</p>
    </div>
  </div>
</template>

<script>
import { imgUrlHead } from '@/util/config.js';
export default {
  props: {
    searchBox: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
    }
  }
}
</script>

<style lang="less" scoped>
.positon {
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  margin-right: 10px;

  #positionIcon {
    width: 16px !important;
    height: 16px !important;
    margin-right: 5px;
  }
}


.searchBox {
  width: 100%;
  padding: 3px 14px;
  display: flex;
  align-items: center;

  .search {
    flex: 1;
    height: 40px;
    border-radius: 20px;
    border: 1px solid;
    padding-left: 20px;
    display: flex;
    align-items: center;

    i {
      font-size: 12px;
    }

    p {
      margin-left: 10px;
      font-size: 12px;
    }
  }
}
</style>
