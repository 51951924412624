<template>
  <div class="collageBigBox" :style="{ background: collageConfig.bgColor }">
    <!-- 两列展示 -->
    <div class="twoColLayout" v-if="collageConfig.layout === 0">
      <div :class="['goodsItem', index === 0 || index === 1 ? '' : 'mt10']" v-for="(item, index) in collageConfig.list" :key="index">
        <div class="top">
          <img :src="item.picture" alt="" />
          <div
            class="timeDown"
            :style="{
              '--color': collageConfig.collageColorSystem,
              color: collageConfig.collageColorSystem,
            }"
          >
            <div>
              {{ item.isStart ? '距结束' : '拼团开始' }}
            </div>
            <div v-if="item.isStart">
              <span v-if="item.day > 0">{{ item.day }} :</span>
              {{ item.hour }} : {{ item.minutes }} : {{ item.seconds }}
            </div>
            <div v-else>{{ item.month }}月{{ item.day }}日 {{ item.hour }} : {{ item.minutes }}</div>
          </div>
        </div>
        <div class="bot">
          <div class="goods_title" :style="{ color: collageConfig.goodsColorSystem }">
            {{ item.goods_name }}
          </div>
          <div class="goods_subTitle" :style="{ color: collageConfig.goodsColorSystem }">
            {{ item.goods_subtitle }}
          </div>
          <div class="infoBox">
            <div class="leftTop">
              <div
                class="groupPrice"
                :style="{
                  '--color': collageConfig.collageColorSystem,
                  background: collageConfig.collageColorSystem,
                }"
              >
                团购价
              </div>
              <div class="price" :style="{ color: collageConfig.collageColorSystem }">
                <span style="font-size: 12px">￥</span>
                {{ item.group_price }}
              </div>
            </div>
            <div class="leftBot" :style="{ color: collageConfig.collageColorSystem }">
              <del>日常价:￥{{ item.goods_price }}</del>
            </div>
            <div class="btnTxt" :style="{ background: item.isStart ? collageConfig.collageColorSystem : '#C7C7C7' }">
              {{ item.isStart ? '去拼团' : '未开团' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 单行滚动 -->
    <div class="oneRowRoll" v-if="collageConfig.layout === 1">
      <div :class="['goodsItem']" v-for="(item, index) in collageConfig.list" :key="index">
        <div class="top">
          <img :src="item.picture" alt="" />
          <div
            class="timeDown"
            :style="{
              '--color': collageConfig.collageColorSystem,
              color: collageConfig.collageColorSystem,
            }"
          >
            <i class="el-icon-timer"></i>
            <div v-if="item.isStart">
              <span v-if="item.day > 0">{{ item.day }} :</span>
              {{ item.hour }} : {{ item.minutes }} : {{ item.seconds }}
            </div>
            <div v-else>{{ item.month }}月{{ item.day }}日 {{ item.hour }} : {{ item.minutes }}</div>
          </div>
        </div>
        <div class="bot">
          <div class="name" :style="{ color: collageConfig.goodsColorSystem }">
            {{ item.goods_name }}
          </div>
          <div class="groupPriceBox">
            <div
              class="groupPrice"
              :style="{
                '--color': collageConfig.collageColorSystem,
                background: collageConfig.collageColorSystem,
              }"
            >
              团购价
            </div>
            <div class="price" :style="{ color: collageConfig.collageColorSystem }">
              <span style="font-size: 12px">￥</span>
              {{ item.group_price }}
            </div>
          </div>
          <div class="dayPrice" :style="{ color: collageConfig.collageColorSystem }">
            <del>日常价:￥{{ item.goods_price }}</del>
          </div>
        </div>
      </div>
    </div>
    <!-- 列表显示 -->
    <div class="listShow" v-if="collageConfig.layout === 2">
      <div class="goodsItem" v-for="(item, index) in collageConfig.list" :key="index">
        <div class="left">
          <img :src="item.picture" alt="" />
          <div
            class="timeDown"
            :style="{
              '--color': collageConfig.collageColorSystem,
              color: collageConfig.collageColorSystem,
              height: '25px',
              lineHeight: '25px',
            }"
          >
            <i class="el-icon-timer"></i>
            <div v-if="item.isStart">
              <span v-if="item.day > 0">{{ item.day }} :</span>
              {{ item.hour }} : {{ item.minutes }} : {{ item.seconds }}
            </div>
            <div v-else>{{ item.month }}月{{ item.day }}日 {{ item.hour }} : {{ item.minutes }}</div>
          </div>
        </div>
        <div class="right">
          <div class="rtop" :style="{ color: collageConfig.goodsColorSystem }">
            {{ item.goods_name }}
          </div>
          <div class="rbot">
            <div class="groupPriceBox">
              <div
                class="groupPrice"
                :style="{
                  '--color': collageConfig.collageColorSystem,
                  background: collageConfig.collageColorSystem,
                }"
              >
                团购价
              </div>
              <div class="price" :style="{ color: collageConfig.collageColorSystem }">
                <span style="font-size: 12px">￥</span>
                {{ item.group_price }}
              </div>
            </div>
            <div class="dayPrice" :style="{ color: collageConfig.collageColorSystem }">
              <del>日常价:￥{{ item.goods_price }}</del>
            </div>
            <div class="btnTxt" :style="{ background: item.isStart ? collageConfig.collageColorSystem : '#C7C7C7' }">
              {{ item.isStart ? '去拼团' : '未开团' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collageConfig: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="less" scoped>
.collageBigBox {
  width: 100%;
  padding: 3px 13px;
}
.twoColLayout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .goodsItem {
    width: 165px;
    box-shadow: 0px 2px 8px 0px rgba(204, 200, 201, 0.24);
    border-radius: 8px;
    overflow: hidden;
    .top {
      width: 100%;
      height: 165px;
      position: relative;
      overflow: hidden;
    }
    .bot {
      width: 100%;
      height: 126px;
      background-color: #fff;
      padding: 10px;
      .goods_title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 32px;
      }
      .goods_subTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 5px 0;
        opacity: 0.5;
        font-size: 12px;
      }
      .infoBox {
        position: relative;
        .leftTop {
          display: flex;
          align-items: flex-end;
          .price {
            margin-left: 12px;
            font-size: 16px;
            font-weight: bold;
          }
        }
        .leftBot {
          font-size: 12px;
          margin-top: 8px;
        }
        .btnTxt {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 50px;
          height: 20px;
          border-radius: 10px;
          font-size: 12px;
          color: #fff;
          text-align: center;
          line-height: 20px;
        }
      }
    }
  }
  .mt10 {
    margin-top: 10px;
  }
}
del {
  text-decoration: line-through !important;
  opacity: 0.5;
}
.groupPrice {
  height: 20px;
  line-height: 20px;
  color: #fff;
  position: relative;
  font-size: 12px;
  padding: 0 3px;
  white-space: nowrap;
  transform: scale(0.8);
  margin-left: -2px;
  &::after {
    content: '';
    position: absolute;
    right: -20px;
    top: 0;
    width: 0;
    height: 0;
    border: 10px solid var(--color);
    border-color: transparent transparent transparent var(--color);
  }
}
.timeDown {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 36px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  white-space: nowrap;
  padding: 0 10px;
  i {
    font-size: 15px;
  }
  &::after {
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    background-color: var(--color);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
  }
}
.oneRowRoll {
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-x: auto;
  .goodsItem {
    flex-shrink: 0;
    width: 125px;
    height: 205px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 8px 0px rgba(204, 200, 201, 0.24);
    &:not(:last-child) {
      margin-right: 10px;
    }
    .top {
      width: 100%;
      height: 125px;
      position: relative;
      overflow: hidden;
    }
    .bot {
      width: 100%;
      height: 80px;
      background-color: #fff;
      padding: 10px;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .groupPriceBox {
        display: flex;
        align-items: center;
        margin: 5px 0;
        justify-content: space-between;
        .price {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .dayPrice {
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
}
.listShow {
  .goodsItem {
    width: 100%;
    height: 115px;
    background: #ffffff;
    border-radius: 8px;
    padding: 5px;
    display: flex;
    box-shadow: 0px 2px 8px 0px rgba(204, 200, 201, 0.24);
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .left {
      width: 105px;
      height: 105px;
      overflow: hidden;
      position: relative;
      margin-right: 15px;
    }
    .right {
      flex: 1;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .rtop {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .rbot {
        position: relative;
        .groupPriceBox {
          display: flex;
          align-items: center;
          .price {
            margin-left: 10px;
            font-style: 16px;
            font-weight: bold;
          }
        }
        .btnTxt {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 60px;
          height: 20px;
          border-radius: 10px;
          font-size: 12px;
          color: #fff;
          text-align: center;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
