<template>
  <div class="imgWindow" :style="{ background: imgWindow.bgColor }">
    <div v-if="imgWindow.imgCount == 4" class="len4">
      <div v-if="imgWindow.layout == 0" class="layout0 flex">
        <div class="imgBox" :style="{ width: '167px', height: '199px' }">
          <img :src="imgWindow.list[0].imgUrl" alt="" />
        </div>
        <div class="flexC" style="height: 199px">
          <div class="imgBox" :style="{ width: '167px', height: '95px' }">
            <img :src="imgWindow.list[1].imgUrl" alt="" />
          </div>
          <div class="flex">
            <div class="imgBox" :style="{ width: '79px', height: '95px' }">
              <img v-if="imgWindow.list[2]" :src="imgWindow.list[2].imgUrl" alt="" />
            </div>
            <div class="imgBox" :style="{ width: '79px', height: '95px' }">
              <img v-if="imgWindow.list[3]" :src="imgWindow.list[3].imgUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="imgWindow.layout == 1" class="layout1 flex">
        <div :class="['imgBox', index == 2 || index == 3 ? 'mtop9' : '']" v-for="(item, index) in imgWindow.list" :key="index">
          <img :src="item.imgUrl" alt="" />
        </div>
      </div>
      <div v-if="imgWindow.layout == 2" class="layout2 flex">
        <div :style="{ width: index == 0 || index == 3 ? '223px' : '111px' }" :class="['imgBox', index == 2 || index == 3 ? 'mtop9' : '']" v-for="(item, index) in imgWindow.list" :key="index">
          <img :src="item.imgUrl" alt="" />
        </div>
      </div>
      <div v-if="imgWindow.layout == 3" class="layout3 flex">
        <div :class="['imgBox']" v-for="(item, index) in imgWindow.list" :key="index">
          <img :src="item.imgUrl" alt="" />
        </div>
      </div>
    </div>
    <div v-if="imgWindow.imgCount == 3" class="len3">
      <div v-if="imgWindow.layout == 0" class="layout0 flex">
        <div class="imgBox" :style="{ width: '167px', height: '199px' }">
          <img :src="imgWindow.list[0].imgUrl" alt="" />
        </div>
        <div class="flexC" :style="{ width: '167px', height: '199px' }">
          <div class="imgBox" :style="{ height: '95px' }">
            <img :src="imgWindow.list[1].imgUrl" alt="" />
          </div>
          <div class="imgBox" :style="{ height: '95px' }">
            <img v-if="imgWindow.list[2]" :src="imgWindow.list[2].imgUrl" alt="" />
          </div>
        </div>
      </div>
      <div v-if="imgWindow.layout == 1" class="layout0 flexC">
        <div class="imgBox" :style="{ width: '343px', height: '95px' }">
          <img :src="imgWindow.list[0].imgUrl" alt="" />
        </div>
        <div class="flex mtop9" :style="{ width: '343px', height: '95px' }">
          <div class="imgBox" :style="{ width: '167px', height: '95px' }">
            <img :src="imgWindow.list[1].imgUrl" alt="" />
          </div>
          <div class="imgBox" :style="{ width: '167px', height: '95px' }">
            <img v-if="imgWindow.list[2]" :src="imgWindow.list[2].imgUrl" alt="" />
          </div>
        </div>
      </div>
      <div v-if="imgWindow.layout == 2 || imgWindow.layout == 3" class="layout0 flex">
        <div class="imgBox" :style="{ width: '108px', height: imgWindow.layout == 2 ? '106px' : '144px' }" v-for="(item, index) in imgWindow.list" :key="index">
          <img :src="item.imgUrl" alt="" />
        </div>
      </div>
    </div>
    <div v-if="imgWindow.imgCount == 2" class="len2 flex">
      <div :style="{ width: imgWindow.layout == 1 ? '223px' : '167px', height: imgWindow.layout == 0 || imgWindow.layout == 1 ? '95px' : imgWindow.layout == 2 ? '167px' : '125px' }" class="imgBox">
        <img :src="imgWindow.list[0].imgUrl" alt="" />
      </div>
      <div :style="{ width: imgWindow.layout == 1 ? '111px' : '167px', height: imgWindow.layout == 0 || imgWindow.layout == 1 ? '95px' : imgWindow.layout == 2 ? '167px' : '125px' }" class="imgBox">
        <img :src="imgWindow.list[1].imgUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgWindow: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.flexLayout(@direction:row, @main:space-between, @second:center) {
  display: flex;
  flex-direction: @direction;
  justify-content: @main;
  align-items: @second;
}
.imgWindow {
  width: 100%;
  padding: 3px 0;
  img {
    width: 100% !important;
    height: 100% !important;
  }
  .flexC {
    .flexLayout(column,space-between,initial);
  }
  .flex {
    .flexLayout();
  }
  & > div {
    margin: 0 13px;
    // margin: 0 auto;
  }
  .len4 {
    .layout1 {
      flex-wrap: wrap;
      & > div {
        width: 167px;
        height: 95px;
      }
    }
    .layout2 {
      flex-wrap: wrap;
      & > div {
        height: 95px;
      }
    }
    .layout3 {
      flex-wrap: wrap;
      & > div {
        width: 79px;
        height: 105px;
      }
    }
  }
  .imgBox {
    border-radius: 8px;
    flex-shrink: 0;
    overflow: hidden;
    // background-color: #ebebf0;
  }
}
.mtop9 {
  margin-top: 9px;
}
</style>
