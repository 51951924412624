<template>
  <div class="headerBox" :style="{ backgroundImage: res }">
    <div>
      <div class="searchBox">
        <div
          class="search"
          :style="{
            background: HeaderBg.searchConfig.bgColor,
            justifyContent: HeaderBg.searchConfig.layout == 0 ? 'flex-start' : 'center',
          }"
        >
          <i class="iconfont icon-sousuo"></i>
          <p>{{ HeaderBg.searchConfig.txt }}</p>
        </div>
      </div>
      <div class="bannerBox">
        <div class="img">
          <div class="imgBox" id="imgBox0">
            <div v-for="(item, index) in HeaderBg.bannerConfig" :key="index">
              <img :src="item.imgURL" alt="" />
            </div>
          </div>
        </div>
        <div class="spotBox" v-if="HeaderBg.bannerConfig.length > 1">
          <div @click="moveImg(index)" :class="['spot', spotActive == index ? 'activeSpot' : '']" v-for="(item, index) in HeaderBg.bannerConfig.length" :key="item"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    HeaderBg: {
      type: Object,
      default: () => {},
    },
    action: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      imgArr: [],
      toNum: 0,
      spotActive: 0,
      number: -1,
      firstLoad: true,
      res: '',
    };
  },
  methods: {
    moveImg(i) {
      this.spotActive = i;
      this.toNum = i * 345;
      document.querySelector(`#imgBox0`).style.transform = `translateX(-${this.toNum}px)`;
    },
  },
  watch: {
    action: {
      handler(val, old) {
        if (val.action == 'delete') {
          if (this.spotActive < val.index) {
            //
          } else if (this.spotActive == val.index) {
            if (val.index == 1 && this.HeaderBg.bannerConfig.length == 1) {
              document.querySelector(`#imgBox0`).style.transform = `translateX(0px)`;
              this.spotActive--;
            } else if (val.index == this.HeaderBg.bannerConfig.length) {
              this.toNum -= 345;
              document.querySelector(`#imgBox0`).style.transform = `translateX(-${this.toNum}px)`;
              this.spotActive--;
            }
          } else {
            this.toNum -= 345;
            document.querySelector(`#imgBox0`).style.transform = `translateX(-${this.toNum}px)`;
            this.spotActive--;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    HeaderBg: {
      handler(val) {
        if (val.layout == 1) {
          if (!val.bgColorOne && !val.bgColorTwo) {
            this.res = 'none';
          } else if (!val.bgColorOne) {
            this.res = `linear-gradient(${val.bgColorTwo},${val.bgColorTwo})`;
          } else if (!val.bgColorTwo) {
            this.res = `linear-gradient(${val.bgColorOne},${val.bgColorOne})`;
          } else {
            this.res = `linear-gradient(${val.bgColorOne},${val.bgColorTwo})`;
          }
        } else if (val.layout == 0) {
          if (!val.bgColorOne) {
            this.res = 'none';
          } else {
            this.res = `linear-gradient(${val.bgColorOne},${val.bgColorOne})`;
          }
        } else if (val.layout == 2) {
          this.res = `url('${val.bgImg}')`;
        }
      },
      deep: true,
      immediate: true,
    },
    spotActive: {
      handler(val) {
        this.$nextTick(() => {
          setTimeout(() => {
            document.getElementById('imgBox0').style.height = document.getElementById('imgBox0').children[val].children[0].clientHeight + 'px';
          }, 200);
        });
      },
      immediate: true,
    },
    'HeaderBg.bannerConfig': {
      handler(val) {
        this.$nextTick(() => {
          document.getElementById('imgBox0').style.height = document.getElementById('imgBox0').children[this.spotActive].children[0].clientHeight + 'px';
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.headerBox {
  width: 100%;
  padding-bottom: 5px;
  background-size: 100% 80%;
  background-repeat: no-repeat;
  & > div {
    margin: 0 13px;
  }
  .bannerBox {
    width: 100%;
    margin: 0 auto;
    position: relative;
    .img {
      width: 345px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 10px;
      .imgBox {
        display: flex;
        transition: 0.3s;
        & > div {
          width: 100%;
          height: 100%;
          flex-shrink: 0;
        }
      }
    }
    .spotBox {
      position: absolute;
      bottom: 10px;
      left: 50%;
      display: flex;
      transform: translateX(-50%);
      z-index: 100;
      .spot {
        display: block;
        width: 16px;
        height: 5px;
        padding: 0;
        color: transparent;
        font-size: 0;
        background: rgba(0, 0, 0, 0.5);
        border: 0;
        border-radius: 1px;
        outline: none;
        cursor: pointer;
        margin-right: 5px;
        transition: all 0.5s;
      }
      .activeSpot {
        width: 24px;
        background: #333;
        opacity: 1;
      }
    }
  }
  .searchBox {
    width: 100%;
    padding: 16px 0;
    .search {
      height: 40px;
      border-radius: 20px;
      border: 1px solid #ccc;
      background: #fff;
      padding-left: 10px;
      display: flex;
      align-items: center;
      i {
        font-size: 12px;
      }
      p {
        margin-left: 10px;
        color: #767676;
        font-size: 12px;
      }
    }
  }
}
</style>
