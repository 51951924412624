<template>
  <div class="guideControlBox">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>线条颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.color"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">线条设置</div>
      <div class="set_item">
        <div>线条高度</div>
        <el-slider :min="0" :max="20" v-model="controlDefault.height"></el-slider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    returnBgColor() {
      this.controlDefault.bgColor = null;
    },
    returnColor() {
      this.controlDefault.color = null;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
