<template>
  <div class="goodsGroupControlBox">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>价格颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.priceColor"></el-color-picker>
          <div class="blue-l" @click="returnPriceColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>加购颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.ShopCarColor"></el-color-picker>
          <div class="blue-l" @click="returnShopCarColor">重置</div>
        </div>
      </div>
    </div>

    <div class="set_s_card">
      <div class="card_title">组件设置</div>
      <div class="set_item flex_start">
        <div>布局方式</div>
        <el-radio-group v-model="controlDefault.layout">
          <el-radio :label="0">单列显示</el-radio>
          <el-radio :label="1">双列显示</el-radio>
          <el-radio :label="2">三列显示</el-radio>
          <el-radio :label="3">列表显示</el-radio>
          <el-radio :label="4">单行滚动</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>商品价格</div>
        <el-radio-group v-model="controlDefault.showPrice">
          <el-radio :label="0">显示</el-radio>
          <el-radio :label="1">不显示</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item flex_start">
        <div>加购物车</div>
        <el-radio-group v-model="controlDefault.addShopCarStyle">
          <el-radio :label="0">样式一</el-radio>
          <el-radio :label="1">样式二</el-radio>
          <el-radio :label="2">不显示</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="set_i_card">
      <div class="card_top" style="margin-bottom: 24px">
        <div class="card_title">商品设置</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加商品" placement="top-end">
            <img style="margin-right: 8px" @click="addItem" v-show="controlDefault.goodsSource == 0"
              :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.list" :key="index">
                <img :src="item.goods_picture" alt="" />
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" v-show="controlDefault.goodsSource == 0" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <div class="set_item">
        <div>商品来源</div>
        <el-radio-group v-model="controlDefault.goodsSource">
          <el-radio :label="0">选择商品</el-radio>
          <el-radio :label="1">选择分类</el-radio>
        </el-radio-group>
      </div>
      <vuedraggable v-show="!controlDefault.goodsSource" v-model="controlDefault.list" v-bind="{
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scroll: true,
      }">
        <transition-group>
          <div class="chooseImage" id="chooseImage" v-for="(item, index) in controlDefault.list" :key="index">
            <div class="imgBox">
              <img :src="item.goods_picture" alt="" />
              <div class="upload" @click="chooseGoods(index)">选择商品</div>
            </div>
            <div class="btnArea">
              <el-input v-model="item.goods_name" disabled placeholder="商品名称"></el-input>
              <el-input v-model="item.goods_price" disabled placeholder="商品价格"></el-input>
            </div>
          </div>
        </transition-group>
      </vuedraggable>

      <div class="set_item" v-show="controlDefault.goodsSource == 1">
        <div>选择分类</div>
        <div class="content">
          <el-input disabled v-model="controlDefault.goodsStyle_name"></el-input>
          <div class="blue-l" @click="showCategoryLog">选择</div>
        </div>
      </div>
      <div class="set_item" v-show="controlDefault.goodsStyle_id != '' && controlDefault.goodsSource == 1">
        <div>商品数量</div>
        <el-slider :min="0" :max="20" v-model="controlDefault.goodsNum" @change="changeNum"></el-slider>
      </div>
    </div>

    <!-- 商品分类弹框 -->
    <el-dialog title="选择分类" :visible.sync="showCategory" width="52%">
      <div>
        <ChooseCategory @getResult="getClass"></ChooseCategory>
      </div>
    </el-dialog>
    <!-- 商品弹框 -->
    <el-dialog title="选择商品" :visible.sync="showGoods" width="52%">
      <ListComponents v-if="showGoods" :styleMethon="style" @getListItem="getItem"></ListComponents>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import ListComponents from '@/components/listComponents';
import ChooseCategory from '@/views/addPages/chooseCategory/index.vue';
import { imgUrlHead } from '@/util/config.js';
export default {
  components: {
    vuedraggable,
    ListComponents,
    ChooseCategory,
  },
  props: {
    controlDefault: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      showCategory: false,
      goodsNum: -1,
      showGoods: false,
      index: -1,
      style: 0,
      total: 0,
      totalList: [],
    };
  },
  methods: {
    // 重置颜色
    returnColor () {
      this.controlDefault.bgColor = null;
    },
    returnPriceColor () {
      this.controlDefault.priceColor = null;
    },
    returnShopCarColor () {
      this.controlDefault.ShopCarColor = null;
    },
    // 显示分类弹框
    showCategoryLog () {
      this.showCategory = true;
    },
    // 点击新增商品
    addItem () {
      this.controlDefault.list.push({
        status: '1',
        goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/620zheng.png',
        goods_name: '商品名称',
        goods_price: '0.01',
      });
    },
    // 删除商品
    deleteItem (index) {
      if (this.controlDefault.list.length <= 1) {
        this.$message.warning('至少添加一个商品!!');
        return;
      }
      this.controlDefault.list.splice(index, 1);
    },
    // 选择商品
    chooseGoods (index) {
      this.index = index;
      this.showGoods = !this.showGoods;
    },
    // 获取到选中的商品
    getItem (item) {
      this.showGoods = false;
      this.$set(this.controlDefault.list, this.index, item);
    },
    // 获取分类
    getClass (id, name) {
      this.controlDefault.goodsStyle_id = id;
      this.controlDefault.goodsStyle_name = name;
      let that = this;
      this.$axios
        .post(this.$api.goods.list, {
          page: 1,
          rows: 20,
          classify_id: id,
        })
        .then(res => {
          if (res.code == 0) {
            that.controlDefault.totalList = JSON.parse(JSON.stringify(res.result.list));
            that.controlDefault.list = res.result.list;
            if (res.result.total_number <= 20) {
              that.controlDefault.goodsNum = res.result.total_number;
            } else {
              that.controlDefault.goodsNum = 20;
            }
          }
        });
      this.showCategory = false;
    },
    changeNum (newNum) {
      if (newNum > this.controlDefault.totalList.length) {
        this.$message({
          message: '不可超过该分类商品的最大数量',
          type: 'warning',
        });
        this.controlDefault.goodsNum = this.controlDefault.totalList.length;
      }
      this.controlDefault.list = [...this.controlDefault.totalList].splice(0, newNum);
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
