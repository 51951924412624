<template>
  <div class="cardBox">
    <div class="layout_0" v-if="cardItemConfig.layout === 0">
      <div class="title">{{ cardItemConfig.dataObj.card_item_name }}</div>
      <div class="subTitle">{{ cardItemConfig.sub_title }}</div>
      <div class="bottomBox">
        <div class="left">
          <div class="price">
            <span>￥</span>{{ cardItemConfig.dataObj.card_item_price }}<span>/次</span>
          </div>
          <div class="save">比平时省￥~~</div>
        </div>
        <div v-if="cardItemConfig.buyLayout" :class="['btn_' + cardItemConfig.buyLayout]">{{ cardItemConfig.add_txt }}
        </div>
      </div>
    </div>
    <div class="layout_1 " v-if="cardItemConfig.layout === 1 || cardItemConfig.layout === 2">
      <div class="imgBox" v-if="cardItemConfig.layout === 1">
        <img :src="cardItemConfig.dataObj.card_item_picture" alt="">
      </div>
      <div class="right">
        <div class="top">
          <div class="title">{{ cardItemConfig.dataObj.card_item_name }}</div>
          <div class="subTitle">{{ cardItemConfig.sub_title }}</div>
        </div>
        <div class="bottomBox">
          <div class="price">
            <span>￥</span>{{ cardItemConfig.dataObj.card_item_price }}
          </div>
          <div v-if="cardItemConfig.buyLayout" :class="['btn_' + cardItemConfig.buyLayout]">{{ cardItemConfig.add_txt }}
          </div>
        </div>
      </div>
      <div class="imgBox layout_2" v-if="cardItemConfig.layout === 2">
        <img :src="cardItemConfig.dataObj.card_item_picture" alt="">
      </div>
    </div>
    <div class="layout_3" v-if="cardItemConfig.layout === 3">
      <div class="imgBox">
        <img :src="cardItemConfig.dataObj.card_item_picture" alt="">
        <!--<div class="title">{{ cardItemConfig.dataObj.card_item_name }}</div>-->
      </div>
      <div class="bottomBox">
        <div class="left">
          <!--<div>可用次数:{{ cardItemConfig.dataObj.usable_sum || 0 }}次</div>
          <div>到期时间:{{ getIndate(cardItemConfig.dataObj) }}</div>-->
          <div class="top">
            <div class="title">{{ cardItemConfig.dataObj.card_item_name }}</div>
            <div class="subTitle">{{ cardItemConfig.sub_title }}</div>
          </div>
          <div class="price">
            <span>￥</span>{{ cardItemConfig.dataObj.card_item_price
            }}<span>/{{ cardItemConfig.dataObj.usable_sum || 0 }}次</span>
          </div>
        </div>
        <div v-if="cardItemConfig.buyLayout" :class="['btn_' + cardItemConfig.buyLayout]">{{ cardItemConfig.add_txt }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDateformat } from '@/util/getDate.js'
export default {
  props: {
    cardItemConfig: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    getIndate (data) {
      if (data.type === 1) return '永久有效'
      if (data.type === 2) return `购买后${data.indate}天过期`
      if (data.type === 3) return `${getDateformat(data.indate)}`
    },
  }
}
</script>

<style lang="less" scoped>
.noWrap() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title(@fz: 16px) {
  font-size: @fz;
  font-weight: bold;
  color: #333333;
  .noWrap();
}

.cardBox {
  padding: 0 14px;

  .layout_0 {
    width: 100%;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 15px;
    background-color: #fff;

    .title {
      .title();
      margin-bottom: 5px;
    }

    .subTitle {
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      margin-bottom: 11px;
      .noWrap();
    }

    .bottomBox {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        .price {
          font-size: 20px;
          font-weight: bold;
          color: #FF4B51;
          margin-right: 18px;

          span {
            font-size: 14px;
          }
        }

        .save {
          padding: 2px 8px;
          background-color: #FDF5F3;
          color: #FF643B;
          font-size: 12px;
          border-radius: 5px;
          align-self: flex-end;
        }

      }
    }
  }

  .layout_1 {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #FFEBE9;
    padding: 15px;
    display: flex;
    justify-content: space-between;

    .imgBox {
      width: 150px;
      height: 100px;
      overflow: hidden;
      margin-right: 10px;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top {
        .title {
          .title(14px);
          margin-bottom: 5px;
        }

        .subTitle {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }

      .bottomBox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          font-size: 20px;
          font-weight: bold;
          color: #FF4B51;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.layout_2 {
  margin-right: 0px !important;
  margin-left: 10px;
}

.layout_3 {
  padding: 15px 15px 10px 15px;
  background-color: #fff;
  border-radius: 10px;

  .imgBox {
    width: 100%;
    position: relative;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 174px !important;
      border-radius: 10px;
    }

    //.title {
    //  padding: 3px 12px;
    //  position: absolute;
    //  right: 0;
    //  top: 0;
    //  font-size: 12px;
    //  font-weight: 500;
    //  color: #AC7044;
    //  background: linear-gradient(90deg, #FBF1E1 0%, #F0D3B9 100%);
    //  border-radius: 0px 10px 0px 10px;
    //}
  }

  .bottomBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .left {
      .top {
        display: flex;
        align-items: flex-end;
        margin-bottom: 5px;

        .title {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }

        .subTitle {
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-left: 5px;
        }
      }

      .price {
        font-size: 14px;
        font-weight: bold;
        color: #FF4B51;

        span {
          font-size: 12px;
        }
      }
    }

    img {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

.btn_1 {
  padding: 5px 21px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #FF4B51;
  color: #FF4B51;
  font-size: 14px;
}

.btn_2 {
  padding: 5px 21px;
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid #FF4B51;
  color: #FF4B51;
  font-size: 14px;
}

.btn_3 {
  padding: 5px 21px;
  background-color: #FF6B70;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
}

.btn_4 {
  padding: 5px 21px;
  background-color: #FF6B70;
  color: #fff;
  border-radius: 35px;
  font-size: 14px;
}
</style>