<template>
  <div class="btnControl">
    <div class="set_s_card l">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>优惠券颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.componentColor"></el-color-picker>
          <div class="blue-l" @click="returnComponentColor">重置</div>
        </div>
      </div>
    </div>
    <div class="set_s_card l">
      <div class="card_title">组件设置</div>
      <div class="set_item">
        <div>每行数量</div>
        <el-radio-group class="noWarp" @change="changeLayout" v-model="controlDefault.rowCount">
          <el-radio :label="1">1个</el-radio>
          <el-radio :label="2">2个</el-radio>
          <el-radio :label="3">3个</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>优惠券样式</div>
        <el-radio-group v-model="controlDefault.layout">
          <el-radio :label="0">样式一</el-radio>
          <el-radio :label="1">样式二</el-radio>
          <!-- <el-radio :label="2">样式三</el-radio> -->
        </el-radio-group>
      </div>
    </div>

    <div class="set_i_card">
      <div class="card_title">优惠券列表</div>
      <vuedraggable v-model="controlDefault.list" v-bind="{
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scroll: true,
      }">
        <transition-group>
          <div class="coupon" v-for="(item, index) in controlDefault.list" :key="index">
            <div>
              <span>优惠券选择</span>
              <el-input v-model="item.name" disabled placeholder="选择优惠券"></el-input>
              <el-button type="primary" @click="toPageShow(index)">选择</el-button>
            </div>
            <el-input v-model="item.rule" placeholder="暂未添加优惠券链接" disabled></el-input>
          </div>
        </transition-group>
      </vuedraggable>
    </div>

    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes" type="coupon"></ToPage>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import ToPage from '@/views/addPages/toPage/index.vue';
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {
      toPageFlag: false,
      itemIndex: -1,
    };
  },
  components: {
    vuedraggable,
    ToPage,
  },
  methods: {
    // 重置背景颜色颜色
    returnColor () {
      this.controlDefault.bgColor = null;
    },
    // 重置优惠券颜色
    returnComponentColor () {
      this.controlDefault.componentColor = null;
    },

    // 开启选择链接弹框
    toPageShow (index) {
      this.toPageFlag = true;
      this.itemIndex = index;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
        this.$refs.toPage.chooseIndex('myCouponList');
      });
    },
    // 拿到跳转路径
    getPageRes (item) {
      if (item.rule !== undefined) {
        this.$set(this.controlDefault.list, this.itemIndex, item);
        this.toPageFlag = false;
      } else {
        this.$message.warning('该组件为优惠券组件，请选择优惠券。');
        this.$nextTick(() => {
          this.$refs.toPage.chooseIndex('myCouponList');
        });
      }
    },
    // 改变每行数量
    changeLayout (index) {
      if (index < this.controlDefault.list.length) {
        let num = this.controlDefault.list.length - index;
        for (let i = 0; i < num; i++) {
          this.controlDefault.list.splice(this.controlDefault.list.length - 1, 1);
        }
      } else if (index > this.controlDefault.list.length) {
        let num = index - this.controlDefault.list.length;
        for (let i = 0; i < num; i++) {
          this.controlDefault.list.push({
            consume_full: '条件',
            type: 1,
            subtract: '金额',
            discount: '折扣',
            name: '优惠券名称',
            rule: '优惠券使用规则',
            id: null,
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);

.coupon {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &>div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    span {
      flex-shrink: 0;
      margin-right: 5px;
    }
  }
}
</style>
