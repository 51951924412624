<template>
  <div class="style_0">
    <div class="set_s_card">
      <div class="card_title">文字设置</div>
      <div class="set_item">
        <div>字段1</div>
        <el-input v-model="controlDefault.txt_1"></el-input>
      </div>
      <div class="set_item">
        <div>字段2</div>
        <el-input v-model="controlDefault.txt_2"></el-input>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>字体颜色</div>
        <div class="content">
          <div class="content">
            <el-color-picker v-model="controlDefault.fontColor"></el-color-picker>
            <div class="blue-l" @click="controlDefault.fontColor = '#333'">重置</div>
          </div>
        </div>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">跳转设置</div>
      <div class="set_item">
        <div>跳转路径</div>
        <div class="content">
          <el-input disabled v-model="controlDefault.toPage" placeholder="请选择跳转路径"></el-input>
          <div class="blue-l" @click="choosePage">选择</div>
        </div>
      </div>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import ToPage from '@/views/addPages/toPage/index.vue';
export default {
  props: {
    controlDefault: {
      type: Array,
      default: () => [],
    },

  },
  components: {
    ToPage
  },
  data () {
    return {
      toPageFlag: false,
    }
  },
  methods: {
    choosePage () {
      this.toPageFlag = true;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes (item) {
      this.controlDefault.toPage = item.wxroute;
      this.toPageFlag = false;
    },
  }
}
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>