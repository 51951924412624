<template>
  <div class="bannerControl">
    <div class="set_s_card">
      <div class="card_title">背景设置</div>
      <div class="set_item">
        <div>选择边距</div>
        <el-radio-group v-model="controlDefault.chooseMargin">
          <el-radio :label="1">左右边距</el-radio>
          <el-radio :label="0">无边距</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnbgColor">重置</div>
        </div>
      </div>
    </div>

    <div class="set_i_card">
      <div class="card_top">
        <div class="card_title">轮播图设置</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加banner图" placement="top-end">
            <img style="margin-right: 8px" @click="addBanner" :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.list" :key="index">
                <img :src="item.imgURL" alt="" />
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <div class="tips" style="margin-top: 8px">*建议尺寸686px*320px，上传图片不超过1M，支持 png、bmg、jpeg、jpg、gif</div>
      <vuedraggable v-model="controlDefault.list" v-bind="{
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scroll: true,
      }">
        <transition-group>
          <div class="chooseImage" v-for="(item, index) in controlDefault.list" :key="index">
            <div class="imgBox">
              <img :src="item.imgURL" alt="" />
            </div>
            <div class="btnArea">
              <div class="blue-b" @click="chooseImg(index)">选择图片</div>
              <div class="blue-b" @click="choosePage(index)">选择路径</div>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>

    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
    <!-- 选择图片弹框 -->
    <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag"
      @getSource="getImgRes" :maxSize="1024 * 1024"></UploadSource>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import UploadSource from '@/components/uploadSource';
import ToPage from '@/views/addPages/toPage/index.vue';
import { imgUrlHead } from '@/util/config.js';
export default {
  props: {
    controlDefault: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    vuedraggable,
    UploadSource,
    ToPage,
  },
  data () {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      index: -1,
      toPageFlag: false,
      chooseImgFlag: false,
      itemIndex: -1,
    };
  },
  updated () {
    this.$emit('getImg', this.controlDefault);
  },
  methods: {
    changeStatus (val) {
      this.chooseImgFlag = val;
    },
    returnbgColor () {
      this.controlDefault.bgColor = null;
    },
    addBanner () {
      if (this.controlDefault.list.length == 5) {
        this.$message({
          message: '最多只可以添加五张banner图哦~',
          type: 'error',
        });
        return;
      }
      this.controlDefault.list.push({
        status: '1',
        imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bannerDefault.png',
        toPage: '跳转页面',
      });
    },
    // 删除对应banner图
    deleteItem (index) {
      if (this.controlDefault.list.length === 1) {
        this.$message.warning('至少保留一张banner');
        return;
      }
      this.controlDefault.list.splice(index, 1);
      this.$emit('getImg', this.controlDefault, { action: 'delete', index });
    },
    // 选择图片
    chooseImg (index) {
      this.index = index;
      this.chooseImgFlag = true;
    },
    // 选择跳转路径
    choosePage (index) {
      this.toPageFlag = true;
      this.itemIndex = index;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes (item) {
      this.controlDefault.list[this.itemIndex].toPage = item.wxroute;
      this.toPageFlag = false;
    },
    // 拿到图片
    getImgRes (imglist) {
      this.controlDefault.list[this.index].imgURL = imglist[0].path;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
