<template>
  <div class="cardItem">
    <div class="set_s_card">
      <div class="card_title">样式设置</div>
      <div class="set_item flex_start">
        <div>组件样式</div>
        <el-radio-group v-model="controlDefault.layout">
          <el-radio :label="0">组件一</el-radio>
          <el-radio :label="1">组件二</el-radio>
          <el-radio :label="2">组件三</el-radio>
          <el-radio :label="3">组件四</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item flex_start">
        <div>购买样式</div>
        <el-radio-group v-model="controlDefault.buyLayout">
          <el-radio :label="0">不展示</el-radio>
          <el-radio :label="1">样式一</el-radio>
          <el-radio :label="2">样式二</el-radio>
          <el-radio :label="3">样式三</el-radio>
          <el-radio :label="4">样式四</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>组件色系</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.colorSystem"></el-color-picker>
          <div class="blue-l" @click="controlDefault.colorSystem = '#FF4B51'">重置</div>
        </div>
      </div>
    </div>
    <div class="set_s_card l">
      <div class="card_title">字段设置</div>
      <div class="set_item">
        <div>副标题字段</div>
        <el-input v-model="controlDefault.sub_title"></el-input>
      </div>
      <div class="set_item">
        <div>加购字段</div>
        <el-input :maxlength="4" v-model="controlDefault.add_txt"></el-input>
      </div>
    </div>
    <div class="set_i_card">
      <div class="card_top">
        <div class="card_title">卡项设置</div>
      </div>
      <div class="chooseBtn">
        <div class="imageBox">
          <img v-if="controlDefault.dataObj.card_item_picture" :src="controlDefault.dataObj.card_item_picture" alt="" />
          <div class="upload" @click="chooseCards(index)">选择卡项</div>
        </div>
        <div class="btnConfig">
          <div>
            <el-input placeholder="卡项名称" disabled v-model="controlDefault.dataObj.card_item_name"></el-input>
          </div>
          <div>
            <el-input placeholder="卡项金额" v-model="controlDefault.dataObj.card_item_price" disabled></el-input>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes" type="cardItem"></ToPage>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ToPage from '@/views/addPages/toPage/index.vue';
export default {
  components: {
    ToPage
  },
  props: {
    controlDefault: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      index: -1,
      toPageFlag: false
    }
  },
  methods: {
    chooseCards (index) {
      this.index = index
      this.toPageFlag = true
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
        this.$refs.toPage.chooseIndex('cardList');
      });
    },
    // 获取选中的卡项
    getPageRes (data) {
      this.controlDefault.dataObj = data
      this.toPageFlag = false;
    }
  }
}
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);

.chooseBtn {
  img {
    border-radius: 0 !important;
  }

  .btnConfig {
    width: 100%;

    &>div {
      width: 100%;
    }
  }

  .el-input {
    margin-right: 0 !important;
  }
}
</style>