<template>
  <div class="style_7" :style="{ backgroundColor: config.bgColor }">
    <div class="content">
      <div class="imgBox">
        <img :src="config.img_1" alt="">
      </div>
      <div class="right">
        <div class="top">
          <div class="title" :style="{ color: config.titleColor }">{{ config.txt_1 }}</div>
          <div class="subTitle" :style="{ color: config.subTitleColor }">{{ config.txt_2 }}</div>
        </div>
        <div class="bot">
          <div class="price">
            <div :style="{ color: choose_s_color.main_color }"><span>￥</span>{{ config.price_2
            }}<span style="margin-left:5px">抢购价</span></div>
            <div>原价:{{ config.price_1 }}</div>
          </div>
          <div class="btn" :style="{ backgroundColor: choose_s_color.main_color }">{{ config.txt_3 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => { }
    },
    choose_s_color: {
      type: Object,
      default: () => { }
    }
  }
}
</script>

<style lang="less" scoped>
.style_7 {
  min-width: 375px;
  padding: 6px 14px;

  .content {
    width: 100%;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 3px 6px 1px rgba(172, 172, 172, 0.16);
    border-radius: 10px 10px 10px 10px;
    display: flex;

    .imgBox {
      width: 110px;
      height: 110px;
      flex-shrink: 0;
      border-radius: 5px 5px 5px 5px;
      overflow: hidden;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top {

        .title {
          width: 180px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .subTitle {
          width: 180px;
          font-size: 13px;
          font-weight: 500;
          color: #999999;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }

      .bot {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .price {
          &>div:first-child {
            font-size: 20px;
            font-weight: bold;

            span {
              font-size: 12px;
            }
          }

          &>div:last-child {
            font-size: 12px;
            font-weight: 500;
            color: #CCCCCC;
            text-decoration: line-through;
          }
        }

        .btn {
          padding: 5px 12px;
          border-radius: 15px 15px 15px 15px;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>