<template>
  <div class="searchCbox">
    <div class="set_s_card b">
      <div class="card_title">搜索框设置</div>
      <div class="set_item">
        <div>是否固定在顶部</div>
        <el-radio-group v-model="controlDefault.isFixed">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>是否启用定位</div>
        <el-radio-group v-model="controlDefault.isPositon">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">文字设置</div>
      <div class="set_item">
        <div>文字字段</div>
        <el-input v-model="controlDefault.tipsTxt" placeholder="仅用于后台查找"></el-input>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.color"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>内部颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.searchBg"></el-color-picker>
          <div class="blue-l" @click="returnsearchBg">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>边框颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.searchBorder"></el-color-picker>
          <div class="blue-l" @click="returnsearchBorder">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>字体颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.fontColor"></el-color-picker>
          <div class="blue-l" @click="returnfontColor">重置</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    returnColor () {
      this.controlDefault.color = null;
    },
    returnsearchBg () {
      this.controlDefault.searchBg = null;
    },
    returnfontColor () {
      this.controlDefault.fontColor = '#000';
    },
    returnsearchBorder () {
      this.controlDefault.searchBorder = '#ccc';
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
