<template>
  <div :style="{ background: GoodsGroup.bgColor, padding: '3px 13px' }">
    <div v-if="GoodsGroup.layout == 0 || GoodsGroup.layout == 1 || GoodsGroup.layout == 2" :class="[GoodsGroup.layout == 0 ? 'goodsGroupBox' : 'goodsGroupBoxFlex']">
      <div
        :class="GoodsGroup.layout == 0 ? 'goodsBox-one ' : GoodsGroup.layout == 1 ? 'goodsBox-two ' : GoodsGroup.layout == 2 && GoodsGroup.list.length == 2 ? 'goodsBox-two' : 'goodsBox-three'"
        v-for="(item, index) in GoodsGroup.list"
        :key="index"
      >
        <div class="imgBox">
          <img :src="item.goods_picture" alt="" />
        </div>

        <div class="goodsName H25">{{ item.goods_name }}</div>
        <div class="boxBot">
          <div class="price" v-show="GoodsGroup.showPrice == 0" :style="{ color: GoodsGroup.priceColor }">
            <span style="font-size: 12px">￥</span><span style="font-size: 16px">{{ item.goods_price }}</span>
          </div>
          <div class="addCar" v-show="GoodsGroup.addShopCarStyle != 2">
            <i v-show="GoodsGroup.addShopCarStyle == 1" :style="{ color: GoodsGroup.ShopCarColor, 'font-size': '20px' }" class="iconfont icon-icon8"></i>
            <div v-show="GoodsGroup.addShopCarStyle == 0" class="addIcon" :style="{ background: GoodsGroup.ShopCarColor }">
              <i style="color: white" class="el-icon-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 列表显示 -->
    <div v-if="GoodsGroup.layout == 3">
      <div class="listItem" v-for="(item, index) in GoodsGroup.list" :key="index">
        <div class="itemLeft">
          <img :src="item.goods_picture" alt="" />
        </div>
        <div class="itemRight">
          <p class="H25 FWB">{{ item.goods_name }}</p>
          <div style="padding-bottom: 16px">
            <p v-show="GoodsGroup.showPrice == 0" style="font-size: 14px" :style="{ color: GoodsGroup.priceColor }">
              <span style="font-size: 12px">￥</span><span>{{ item.goods_price }}</span>
            </p>
            <div v-show="GoodsGroup.addShopCarStyle != 2" style="padding-right: 10px">
              <i v-show="GoodsGroup.addShopCarStyle == 1" :style="{ color: GoodsGroup.ShopCarColor, 'font-size': '20px' }" class="iconfont icon-icon8"></i>
              <div v-show="GoodsGroup.addShopCarStyle == 0" class="addIcon" :style="{ background: GoodsGroup.ShopCarColor }">
                <i style="color: white" class="el-icon-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 单行显示 -->
    <div v-if="GoodsGroup.layout == 4">
      <div class="row">
        <div v-for="(item, index) in GoodsGroup.list" :key="index">
          <div class="listItem4">
            <div class="imgbox">
              <img :src="item.goods_picture" alt="" />
            </div>
            <!--  -->
            <div class="goodsName H25">{{ item.goods_name }}</div>
            <div style="font-size: 16px" class="bot">
              <p v-show="GoodsGroup.showPrice == 0" :style="{ color: GoodsGroup.priceColor }">
                <span style="font-size: 12px">￥</span><span>{{ item.goods_price }}</span>
              </p>
              <div v-show="GoodsGroup.addShopCarStyle != 2">
                <i v-show="GoodsGroup.addShopCarStyle == 1" :style="{ color: GoodsGroup.ShopCarColor, 'font-size': '20px' }" class="iconfont icon-icon8"></i>
                <div v-show="GoodsGroup.addShopCarStyle == 0" class="addIcon" :style="{ background: GoodsGroup.ShopCarColor }">
                  <i style="color: white" class="el-icon-plus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    GoodsGroup: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'GoodsGroup.layout': {
      handler(val, old) {
        let num = 1
        if (val == 0) num = 1
        if (val == 1) num = 2
        if (val == 2) num = 3
        if (val == 3) num = 2
        if (val == 4) num = 4
        if (this.GoodsGroup.list.length < num) {
          num -= this.GoodsGroup.list.length
          for (let i = 0; i < num; i++) {
            this.GoodsGroup.list.push({
              status: '1',
              goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/620zheng.png',
              goods_name: '商品名称',
              goods_price: '0.01'
            })
          }
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
// .goodsGroupBox {
//   width: 95%;
//   margin: 0 auto;
// }
.goodsGroupBoxFlex {
  // width: 95%;
  // margin: 0 auto;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
}
.goodsBox-one {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-bottom: 5px;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 8px;
  .imgBox {
    width: 357px;
    height: 357px;
    overflow: hidden;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .goodsName {
    padding-left: 10px;
  }
  .boxBot {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.boxBot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.imgBox {
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: 0 auto;
}

.goodsName {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 16px;
  font-size: 14px;
  font-weight: bold;
}

.goodsBox-two {
  width: 48%;
  background-color: #fff;
  margin-bottom: 5px;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 8px;
  // margin-left: 5px;
  .imgBox {
    width: 166px;
    height: 166px;
    overflow: hidden;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .goodsName {
    padding-left: 10px;
  }
  .boxBot {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.goodsBox-two:nth-child(2n) {
  margin-left: 13px;
}
.goodsBox-three:not(:nth-child(3n)) {
  margin-right: 7px;
}
.goodsBox-three {
  width: 110px;
  margin-bottom: 5px;
  background-color: #fff;
  padding-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
  // margin-left: 4px;
  .imgBox {
    width: 110px;
    height: 110px;
    overflow: hidden;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .goodsName {
    padding-left: 10px;
  }
  .boxBot {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.listItem {
  // width: 95%;
  // margin: 0 auto;
  display: flex;
  margin-bottom: 8px;
  background-color: #fff;
  padding: 6px;
  border-radius: 8px;
  overflow: hidden;
  .itemLeft {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .itemRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-break: break-word;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.row {
  min-width: 355px;
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  & > div {
    width: 120px;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
    background-color: #fff;
    display: flex;
  }
  .listItem4 {
    width: 100%;
    .imgbox {
      width: 120%;
      height: 120px;
      overflow: hidden;
      img {
        width: 100% !important;
        height: 100% !important;
      }
    }
    .goodsName {
      padding-left: 10px;
      margin-bottom: 8px;
    }
    .bot {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      margin-bottom: 8px;
    }
  }
}
.H25 {
  height: 20px;
  line-height: 20px;
}

.addIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-weight: bold;
  }
}
.FWB {
  font-weight: bold;
}
</style>
