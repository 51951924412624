var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collageBigBox",style:({ background: _vm.collageConfig.bgColor })},[(_vm.collageConfig.layout === 0)?_c('div',{staticClass:"twoColLayout"},_vm._l((_vm.collageConfig.list),function(item,index){return _c('div',{key:index,class:['goodsItem', index === 0 || index === 1 ? '' : 'mt10']},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":item.picture,"alt":""}}),_c('div',{staticClass:"timeDown",style:({
            '--color': _vm.collageConfig.collageColorSystem,
            color: _vm.collageConfig.collageColorSystem,
          })},[_c('div',[_vm._v(" "+_vm._s(item.isStart ? '距结束' : '拼团开始')+" ")]),(item.isStart)?_c('div',[(item.day > 0)?_c('span',[_vm._v(_vm._s(item.day)+" :")]):_vm._e(),_vm._v(" "+_vm._s(item.hour)+" : "+_vm._s(item.minutes)+" : "+_vm._s(item.seconds)+" ")]):_c('div',[_vm._v(_vm._s(item.month)+"月"+_vm._s(item.day)+"日 "+_vm._s(item.hour)+" : "+_vm._s(item.minutes))])])]),_c('div',{staticClass:"bot"},[_c('div',{staticClass:"goods_title",style:({ color: _vm.collageConfig.goodsColorSystem })},[_vm._v(" "+_vm._s(item.goods_name)+" ")]),_c('div',{staticClass:"goods_subTitle",style:({ color: _vm.collageConfig.goodsColorSystem })},[_vm._v(" "+_vm._s(item.goods_subtitle)+" ")]),_c('div',{staticClass:"infoBox"},[_c('div',{staticClass:"leftTop"},[_c('div',{staticClass:"groupPrice",style:({
                '--color': _vm.collageConfig.collageColorSystem,
                background: _vm.collageConfig.collageColorSystem,
              })},[_vm._v(" 团购价 ")]),_c('div',{staticClass:"price",style:({ color: _vm.collageConfig.collageColorSystem })},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("￥")]),_vm._v(" "+_vm._s(item.group_price)+" ")])]),_c('div',{staticClass:"leftBot",style:({ color: _vm.collageConfig.collageColorSystem })},[_c('del',[_vm._v("日常价:￥"+_vm._s(item.goods_price))])]),_c('div',{staticClass:"btnTxt",style:({ background: item.isStart ? _vm.collageConfig.collageColorSystem : '#C7C7C7' })},[_vm._v(" "+_vm._s(item.isStart ? '去拼团' : '未开团')+" ")])])])])}),0):_vm._e(),(_vm.collageConfig.layout === 1)?_c('div',{staticClass:"oneRowRoll"},_vm._l((_vm.collageConfig.list),function(item,index){return _c('div',{key:index,class:['goodsItem']},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":item.picture,"alt":""}}),_c('div',{staticClass:"timeDown",style:({
            '--color': _vm.collageConfig.collageColorSystem,
            color: _vm.collageConfig.collageColorSystem,
          })},[_c('i',{staticClass:"el-icon-timer"}),(item.isStart)?_c('div',[(item.day > 0)?_c('span',[_vm._v(_vm._s(item.day)+" :")]):_vm._e(),_vm._v(" "+_vm._s(item.hour)+" : "+_vm._s(item.minutes)+" : "+_vm._s(item.seconds)+" ")]):_c('div',[_vm._v(_vm._s(item.month)+"月"+_vm._s(item.day)+"日 "+_vm._s(item.hour)+" : "+_vm._s(item.minutes))])])]),_c('div',{staticClass:"bot"},[_c('div',{staticClass:"name",style:({ color: _vm.collageConfig.goodsColorSystem })},[_vm._v(" "+_vm._s(item.goods_name)+" ")]),_c('div',{staticClass:"groupPriceBox"},[_c('div',{staticClass:"groupPrice",style:({
              '--color': _vm.collageConfig.collageColorSystem,
              background: _vm.collageConfig.collageColorSystem,
            })},[_vm._v(" 团购价 ")]),_c('div',{staticClass:"price",style:({ color: _vm.collageConfig.collageColorSystem })},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("￥")]),_vm._v(" "+_vm._s(item.group_price)+" ")])]),_c('div',{staticClass:"dayPrice",style:({ color: _vm.collageConfig.collageColorSystem })},[_c('del',[_vm._v("日常价:￥"+_vm._s(item.goods_price))])])])])}),0):_vm._e(),(_vm.collageConfig.layout === 2)?_c('div',{staticClass:"listShow"},_vm._l((_vm.collageConfig.list),function(item,index){return _c('div',{key:index,staticClass:"goodsItem"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":item.picture,"alt":""}}),_c('div',{staticClass:"timeDown",style:({
            '--color': _vm.collageConfig.collageColorSystem,
            color: _vm.collageConfig.collageColorSystem,
            height: '25px',
            lineHeight: '25px',
          })},[_c('i',{staticClass:"el-icon-timer"}),(item.isStart)?_c('div',[(item.day > 0)?_c('span',[_vm._v(_vm._s(item.day)+" :")]):_vm._e(),_vm._v(" "+_vm._s(item.hour)+" : "+_vm._s(item.minutes)+" : "+_vm._s(item.seconds)+" ")]):_c('div',[_vm._v(_vm._s(item.month)+"月"+_vm._s(item.day)+"日 "+_vm._s(item.hour)+" : "+_vm._s(item.minutes))])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"rtop",style:({ color: _vm.collageConfig.goodsColorSystem })},[_vm._v(" "+_vm._s(item.goods_name)+" ")]),_c('div',{staticClass:"rbot"},[_c('div',{staticClass:"groupPriceBox"},[_c('div',{staticClass:"groupPrice",style:({
                '--color': _vm.collageConfig.collageColorSystem,
                background: _vm.collageConfig.collageColorSystem,
              })},[_vm._v(" 团购价 ")]),_c('div',{staticClass:"price",style:({ color: _vm.collageConfig.collageColorSystem })},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("￥")]),_vm._v(" "+_vm._s(item.group_price)+" ")])]),_c('div',{staticClass:"dayPrice",style:({ color: _vm.collageConfig.collageColorSystem })},[_c('del',[_vm._v("日常价:￥"+_vm._s(item.goods_price))])]),_c('div',{staticClass:"btnTxt",style:({ background: item.isStart ? _vm.collageConfig.collageColorSystem : '#C7C7C7' })},[_vm._v(" "+_vm._s(item.isStart ? '去拼团' : '未开团')+" ")])])])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }