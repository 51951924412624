<template>
  <div class="titleBarBig" :style="{ background: TitleBar.bgColor }">
    <div class="titleBarBox" v-show="TitleBar.isSubTitle == 0" :style="{ justifyContent: TitleBar.layout == 0 ? 'flex-start' : 'center' }">
      <div class="imgBox" v-show="TitleBar.titleImg != ''">
        <img :src="TitleBar.titleImg" alt="" />
      </div>
      <span :style="{ color: TitleBar.mainColor, fontSize: TitleBar.mainFontSize + 'px' }"
        ><b>{{ TitleBar.contant }}</b></span
      >
      <i v-if="TitleBar.toPage != ''" class="el-icon-arrow-right topage"></i>
    </div>
    <div class="subTitleBox" v-show="TitleBar.isSubTitle == 1" :style="{ justifyContent: TitleBar.layout == 0 ? 'flex-start' : 'center' }">
      <div class="mainTitle" :style="{ color: TitleBar.mainColor, fontSize: TitleBar.mainFontSize + 'px' }">
        <b>{{ TitleBar.contant }}</b>
      </div>
      <div class="mainTitle" :style="{ color: TitleBar.secondColor, fontSize: TitleBar.secondFontSize + 'px', marginLeft: '10px' }">
        {{ TitleBar.SubTitle }}
      </div>
      <i v-if="TitleBar.toPage != ''" class="el-icon-arrow-right topage"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    TitleBar: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.titleBarBig {
  width: 100%;
  padding: 3px 0;
  & > div {
    margin: 0 13px;
  }
}
.titleBarBox {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 18px;
  .imgBox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    overflow: hidden;
  }
}
.subTitleBox {
  display: flex;
  align-items: center;
  position: relative;
}
.topage {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
</style>
