<template>
  <div class="noticeControlBox">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnbgColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>公告颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.NbgColor"></el-color-picker>
          <div class="blue-l" @click="returnNbgColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>字体颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.color"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
    </div>

    <div class="set_s_card">
      <div class="card_title">公告栏样式设置</div>
      <div class="set_item">
        <div>显示金价</div>
        <el-radio-group v-model="controlDefault.isGoldPrice">
          <el-radio :label="0">显示</el-radio>
          <el-radio :label="1">不显示</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>通知图标</div>
        <el-radio-group v-model="controlDefault.imgStyle">
          <el-radio :label="0">默认图标</el-radio>
          <el-radio :label="1">上传图片</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item flex_start" v-show="controlDefault.imgStyle == 1">
        <div>选择图片</div>
        <div class="content">
          <div class="tips">*建议图片尺寸48px*48px</div>
          <div class="operationBox">
            <div class="imgBox">
              <img v-if="controlDefault.imgUrl != ''" :src="controlDefault.imgUrl" alt="" />
              <div class="upload" @click="chooseImg()">选择图片</div>
            </div>
            <div class="del-l" @click="clearImg()">清除</div>
          </div>
        </div>
      </div>
      <div class="set_item">
        <div>框体高度</div>
        <el-slider :min="20" :max="120" v-model="controlDefault.boxHeight"></el-slider>
      </div>
      <div class="set_item">
        <div>圆角弧度</div>
        <el-slider :max="controlDefault.boxHeight / 2" v-model="controlDefault.radiusNum"></el-slider>
      </div>
    </div>

    <div class="set_i_card" v-if="controlDefault.isGoldPrice === 1">
      <div class="card_top" style="margin-bottom:20px">
        <div class="card_title">公告设置</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加公告" placement="top-end">
            <img style="margin-right: 8px" @click="addNotice" :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.list" :key="index">
                <div>这是第{{ index + 1 }}条公告</div>
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <vuedraggable v-model="controlDefault.list" v-bind="{
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scroll: true,
      }">
        <transition-group>
          <div v-for="(item, index) in controlDefault.list" :key="index">
            <div class="set_item" style="margin-bottom:6px">
              <div>公告标题</div>
              <el-input v-model="item.title"></el-input>
            </div>
            <div class="set_item" style="margin-bottom:16px">
              <div>跳转路径</div>
              <div class=" content">
                <el-input disabled v-model="item.toPage"></el-input>
                <div class="blue-l" @click="toPageShow(index)">选择</div>
              </div>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
    <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag"
      @getSource="getImgRes">
    </UploadSource>
  </div>
</template>

<script>
import UploadSource from '@/components/uploadSource';
import vuedraggable from 'vuedraggable';
import ToPage from '@/views/addPages/toPage/index.vue';
import { imgUrlHead } from '@/util/config.js';
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      chooseImgFlag: false,
      toPageFlag: false,
      // 选中的公告项
      itemIndex: -1,
    };
  },
  components: {
    UploadSource,
    vuedraggable,
    ToPage,
  },
  methods: {
    changeStatus (val) {
      this.chooseImgFlag = val;
    },
    returnNbgColor () {
      this.controlDefault.NbgColor = null;
    },
    returnbgColor () {
      this.controlDefault.bgColor = null;
    },
    // 重置颜色
    returnColor () {
      this.controlDefault.color = null;
    },
    // 选择图片
    chooseImg (index) {
      this.itemIndex = index;
      this.chooseImgFlag = !this.chooseImgFlag;
    },
    // 清除图片
    clearImg () {
      this.controlDefault.imgUrl = '';
    },
    // 添加
    addNotice () {
      if (this.controlDefault.list.length == 10) {
        this.$message({
          message: '最多只可插入10条公告~',
          type: 'error',
        });
        return;
      }
      this.controlDefault.list.push({
        toPage: '跳转页面',
        title: '这是第一条自定义公告',
      });
    },
    // 删除对应公告图
    deleteItem (index) {
      if (this.controlDefault.list.length <= 1) {
        this.$message.warning('请至少添加一条公告')
        return
      }
      this.controlDefault.list.splice(index, 1);
      this.$emit('sonMethod', this.controlDefault, { action: 'delete', index });
    },
    // 开启选择链接弹框
    toPageShow (index) {
      this.toPageFlag = true;
      this.itemIndex = index;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes (item) {
      this.controlDefault.list[this.itemIndex].toPage = item.wxroute;
      this.toPageFlag = false;
    },
    getImgRes (imglist) {
      this.controlDefault.imgUrl = imglist[0].path;
      if (imglist.length > 1) {
        this.$message({
          message: '只能选取一张图，已自动截取第一张',
          type: 'error',
        });
      }
      this.chooseImgFlag = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);

.tips {
  width: 100%;
}

.operationBox {
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: flex-end;

  .imgBox {
    width: 88px;
    height: 88px;
    background-color: #fff;
    border-radius: 5px;
    margin-right: 16px;
    .flex();

    img {
      width: 80px !important;
      height: 80px !important;
    }

    position: relative;

    .upload {
      cursor: pointer;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 25px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0px 0px 5px 5px;
      .title(14px, #fff, 400);
      .flex();
    }
  }
}

.noticeControlBox {
  padding: 0 20px 20px 20px;
}

.bgColor {
  display: flex;
  align-items: center;
  margin: 20px 0;

  p {
    width: 80px;
    margin-right: 20px;
  }

  .el-color-picker/deep/.el-color-picker__trigger {
    width: 150px;
  }

  .block {
    width: 100%;
  }
}

.chooseImg {
  display: flex;

  // align-items: center;
  &>p {
    width: 80px;
    margin-right: 20px;
  }

  .itemMid {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px dotted #333;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgBox {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
    }

    .upload {
      width: 100%;
      line-height: 25px;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      text-align: center;
    }
  }

  .clearImg {
    color: #6d99ff;
    align-self: flex-end;
    margin-left: 20px;
    cursor: pointer;
    user-select: none;
  }
}

.noticeList {
  max-height: 477px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 20px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /**/
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #f9f9f9;
  }

  &::-webkit-scrollbar-corner {
    background: #204754;
  }
}

.noticeitem {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .left {
    flex: 1;

    &>div {
      display: flex;
      align-items: center;
      width: 100%;

      .el-input {
        max-width: 300px;
      }
    }

    .left-top {
      margin-bottom: 10px;

      p {
        width: 80px;
        margin-right: 20px;
      }
    }

    .left-bot {
      padding-left: 100px;
    }
  }

  .right {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.addOne {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #3c76ff;
  border: 1px solid #3c76ff;
  cursor: pointer;
  margin-top: 20px;
}
</style>
