<template>
  <div class="style_6" :style="{ backgroundColor: config.bgColor }">
    <div class="content" :style="{ backgroundColor: choose_s_color.secondary_color }">
      <div class="imgBox">
        <img :src="config.img_1" alt="">
      </div>
      <div class="bot">
        <div class="left">
          <div class="title" :style="{ color: config.titleColor }">{{ config.txt_1 }}</div>
          <div class="subTitle" :style="{ color: config.subTitleColor }">{{ config.txt_2 }}</div>
        </div>
        <div class="btn" :style="{ backgroundColor: choose_s_color.main_color }">{{ config.txt_3 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => { }
    },
    choose_s_color: {
      type: Object,
      default: () => { }
    }
  }
}
</script>

<style lang="less" scoped>
.style_6 {
  min-width: 375px;
  padding: 6px 14px;

  .content {
    width: 100%;
    padding: 14px;
    border-radius: 10px;

    .imgBox {
      width: 100%;
      height: 150px;
      border-radius: 5px 5px 0 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .bot {
      padding: 17px 15px;
      border-radius: 0 0 5px 5px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          margin-right: 5px;
        }

        .subTitle {
          font-size: 12px;
          font-weight: 400;
        }


      }

      .btn {
        padding: 4px 16px;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        border-radius: 15px 15px 15px 15px;
      }
    }
  }
}
</style>