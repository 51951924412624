<template>
  <div class="style_4" :style="{ backgroundColor: config.bgColor }">
    <div class="content" :style="{ backgroundColor: choose_s_color.secondary_color }">
      <div>
        <div class="top">
          <div class="tips" :style="{ backgroundColor: choose_s_color.secondary_color }">{{ config.txt_1 }}</div>
          <div class="title" :style="{ color: config.titleColor }">{{ config.txt_2 }}</div>
          <div class="subTitle" :style="{ color: choose_s_color.secondary_color }">{{ config.txt_3 }}</div>
        </div>
        <div class="imgBox">
          <img :src="config.img_1" alt="">
        </div>
        <div class="btn" :style="{ backgroundColor: choose_s_color.main_color }">{{ config.txt_4 }}<span></span></div>
      </div>
      <div>
        <div class="top">
          <div class="tips" :style="{ backgroundColor: choose_s_color.secondary_color }">{{ config.txt_5 }}</div>
          <div class="title" :style="{ color: config.titleColor }">{{ config.txt_6 }}</div>
          <div class="subTitle" :style="{ color: choose_s_color.secondary_color }">{{ config.txt_7 }}</div>
        </div>
        <div class="imgBox">
          <img :src="config.img_2" alt="">
        </div>
        <div class="btn" :style="{ backgroundColor: choose_s_color.main_color }">{{ config.txt_8 }}<span></span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => { }
    },
    choose_s_color: {
      type: Object,
      default: () => { }
    }
  }
}
</script>

<style lang="less" scoped>
.style_4 {
  min-width: 375px;
  padding: 6px 14px;

  .content {
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>div {
      &:first-child {
        margin-right: 10px;
      }

      flex: 1;
      flex-shrink: 0;
      max-width: 154px;
      height: 165px;
      background-color: #fff;
      border-radius: 5px 5px 5px 5px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;

      .top {
        padding: 8px 8px 0 8px;

        .tips {
          padding: 2px 9px;
          display: inline-block;
          border-radius: 5px;
          color: #fff;
          font-size: 12px;
          margin-bottom: 10px;
        }

        .title {
          width: 100%;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .subTitle {
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 10px;
        }
      }

      .imgBox {
        flex: 1;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .btn {
        position: absolute;
        bottom: -4px;
        right: -5px;
        padding: 5px 6px;
        font-size: 12px;
        color: #fff;
        border-radius: 5px 0px 5px 0px;
        display: flex;
        align-items: center;
        transform: scale(0.83);

        span {
          display: inline-block;
          border: 8px solid #fff;
          border-color: transparent transparent transparent #fff;
          margin-left: 3px;
        }
      }
    }
  }
}
</style>