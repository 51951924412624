<template>
  <div class="moveBox" id="videoNumberBox">
    <img
      v-if="videoNumberConfig.uploadImgUrl || !videoNumberConfig.videoNumberIconStyle"
      :src="videoNumberConfig.videoNumberIconStyle ? videoNumberConfig.uploadImgUrl : videoNumberConfig.defaultImgUrl"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    videoNumberConfig: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="less" scoped>
.moveBox {
  display: inline-block;
  border-radius: 50%;
}
img {
  width: 88px !important;
  height: 88px !important;
  border-radius: 50%;
}
</style>
