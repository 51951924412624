<template>
  <div class="style_2" :style="{ backgroundColor: config.bgColor }">
    <div class="content">
      <div class="left">
        <div class="title">{{ config.txt_1 }}</div>
        <div class="bot">
          <div class="price" :style="{ '--c': choose_s_color.main_color }"><span class="small">￥{{ config.price_1
          }}</span><span class="big">￥</span>{{ config.price_2
}}</div>
          <div class="btn"
            :style="{ border: `1px solid ${choose_s_color.secondary_color}`, color: choose_s_color.main_color }">{{
                config.txt_2
            }}</div>
        </div>
      </div>
      <div class="imgBox"><img :src="config.img" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => { }
    },
    choose_s_color: {
      type: Object,
      default: () => { }
    }
  }
}
</script>

<style lang="less" scoped>
.style_2 {
  min-width: 375px;
  padding: 6px 14px;

  .content {
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px 10px 10px 10px;
    display: flex;

    .imgBox {
      width: 160px;
      height: 120px;
      border-radius: 5px 0px 0px 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }

      .bot {
        .price {
          //display: inline-block;
          text-align: center;
          margin-bottom: 19px;
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          position: relative;
          z-index: 1;

          &::after {
            content: '';
            display: block;
            width: calc(100% + 10px);
            height: 11px;
            position: absolute;
            bottom: -1px;
            left: -10px;
            z-index: -1;
            background-color: var(--c);
          }

          .small {
            text-decoration: line-through;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
          }

          .big {
            font-size: 14px;
          }
        }

        .btn {
          padding: 5px 22px;
        }
      }
    }
  }
}
</style>