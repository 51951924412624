<template>
  <div class="richBig ql-container ql-snow" :style="{ background: richTxtContant.bgColor, border: 'none' }">
    <div class="richbox content ql-editor" v-html="richTxtContant.richTxt"></div>
  </div>
</template>

<script>

export default {
  props: {
    richTxtContant: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.richBig {
  width: 100%;
  padding: 3px 0;
}
.richbox {
  margin: 0 13px;
  min-height: 50px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;
  word-break: break-word;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /**/
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #f9f9f9;
  }
  &::-webkit-scrollbar-corner {
    background: #204754;
  }
}
</style>
