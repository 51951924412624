<template>
  <div class="shopGroupBox" :style="{ background: ShopGroup.bgColor }">
    <div v-if="ShopGroup.layout == 0">
      <div class="shopItem" v-for="(item, index) in ShopGroup.list" :key="index">
        <div class="left">
          <img :src="item.store_logo" alt="" />
        </div>
        <div class="right">
          <div class="right-top">
            <b>{{ item.store_name }}</b>
          </div>
          <div class="right-bottom">
            {{ item.store_intro }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="ShopGroup.layout == 1">
      <div class="shopList">
        <div class="listItem" v-for="(item, index) in ShopGroup.list" :key="index">
          <div class="shopImg">
            <img :src="item.store_logo" alt="" />
          </div>
          <div style="padding-left: 8px">
            <b>{{ item.store_name }}</b>
          </div>
        </div>
      </div>
    </div>
    <div v-if="ShopGroup.layout == 2" class="shopThirdBox">
      <!-- <div class="ThridTitle">
        <div class="titleMain">
          优质门店
          <div style="font-size: 12px; color: #999; font-weight: normal; margin-left: 10px">/stores</div>
        </div>
        <div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div> -->
      <div class="shopThirdBoxBot">
        <div class="shopThirdItem" v-for="(item, index) in ShopGroup.list" :key="index">
          <div class="shopItemTop">
            <div class="topImg">
              <img :src="item.store_logo" alt="" />
            </div>
            <div class="shopInfo">
              <div>
                <b>{{ item.store_name }}</b>
              </div>
              <div style="font-size: 12px; color: #a3a3a3">{{ item.store_intro }}</div>
            </div>
          </div>
          <div class="shopItemBot">
            <div v-for="(item1, index0) in item.goodsList" :key="index0">
              <img :src="item1.goods_picture" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    ShopGroup: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.shopGroupBox {
  width: 100%;
  padding: 3px 0;
  & > div {
    margin: 0 13px;
  }
}
.shopItem {
  display: flex;
  padding: 8px 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  .left {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 4px;
    overflow: hidden;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 220px;
    .right-bottom {
      font-size: 12px;
      color: #9a9a9a;
    }
  }
}
.shopList {
  width: 100%;
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /**/
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #f9f9f9;
  }
  &::-webkit-scrollbar-corner {
    background: #204754;
  }
  .listItem {
    width: 110px;
    flex-shrink: 0;
    margin-right: 5px;
    background-color: #fff;
    border-radius: 8px;
    padding-bottom: 8px;
    overflow: hidden;
    .shopImg {
      width: 100%;
      height: 100px;
      margin-bottom: 8px;
      overflow: hidden;
    }
  }
}
.shopThirdBox {
  padding: 5px 8px 5px 8px !important;
  .ThridTitle {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    .titleMain {
      display: flex;
      align-items: center;
    }
  }
  .shopThirdBoxBot {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
  }
  .shopThirdItem:not(:last-child) {
    margin-right: 8px;
  }
  .shopThirdItem {
    width: 200px;
    height: 160px;
    border-radius: 16px;
    // border: 1px solid #ccc;
    padding: 16px 8px;
    background-color: #fff;
    .shopItemTop {
      display: flex;
      .topImg {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 7px;
      }
      .shopInfo {
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .shopItemBot {
      width: 172px;
      display: flex;
      justify-content: space-between;
      margin-top: 14px;
      overflow: hidden;
      overflow-y: auto;
      & > div {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        overflow: hidden;
      }
    }
  }
}
</style>
