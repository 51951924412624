<template>
  <div class="style_0" :style="{ backgroundColor: config.bgColor }">
    <div>
      <div class="content" :style="{ border: `1px solid ${choose_s_color.secondary_color}` }">
        <div class="imgBox">
          <img :src="config.img" alt="">
        </div>
        <div class="left">
          <div class="top">
            <div class="title">{{ config.txt_1 }}</div>
            <div class="subtitle">{{ config.txt_2 }}</div>
          </div>
          <div class="bot">
            <div class="price" :style="{ color: choose_s_color.main_color }"><span>￥</span>{{ config.price }}</div>
            <div class="btn" :style="{ backgroundColor: choose_s_color.main_color }">{{ config.txt_3 }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => { }
    },
    choose_s_color: {
      type: Object,
      default: () => { }
    }
  }
}
</script>

<style lang="less" scoped>
.style_0 {
  min-width: 375px;
  padding: 6px 14px;

  &>div {
    padding: 15px;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px 10px 10px 10px;
    background-color: #fff;
  }

  .content {
    width: 100%;
    display: flex;
  }

  .imgBox {
    width: 140px;
    height: 140px;
    border-radius: 5px 0px 0px 5px;
    overflow: hidden;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .left {
    flex: 1;
    padding-top: 10px;
    padding-bottom: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      width: 160px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .subtitle {
      width: 160px;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .bot {
      .price {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;

        span {
          font-size: 14px;
        }
      }

      .btn {
        padding: 5px 12px;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        border-radius: 3px 3px 3px 3px;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }
}
</style>