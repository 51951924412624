<template>
  <div class="shopDetail" :style="{ background: shopDetailConfig.bgColor }">
    <div class="content">
      <div class="bannerBox" v-if="!shopDetailConfig.layout">
        <div class="imgBox" v-for="(item, index) in shopDetailConfig.list" :key="index">
          <img :src="item" alt="">
        </div>
        <div class="spotBox">
          <div :class="['spot', !index ? 'activeSpot' : '']" v-for="(item, index) in shopDetailConfig.list"
            :key="index"></div>
        </div>
      </div>
      <div class="title_1" v-if="!shopDetailConfig.layout">{{ shopDetailConfig.shopName }}</div>
      <div v-if="shopDetailConfig.layout" class="richBox">
        <div class="top">店铺信息</div>
        <div class="info" v-html="shopDetailConfig.desc"></div>
      </div>
      <div class="infoItem">
        <div class="left">营业时间：{{ shopDetailConfig.businessHours }}</div>
        <div class="right"></div>
      </div>
      <div class="infoItem">
        <div class="left">服务地址：{{ shopDetailConfig.address }}</div>
        <div class="right" v-if="shopDetailConfig.navigationLayout">
          <img :src="imgH + 'addressIcon.png'" alt="">
        </div>
      </div>
      <div class="infoItem">
        <div class="left">联系电话：{{ shopDetailConfig.tel }}</div>
        <div class="right"><img :src="imgH + 'telIcon.png'" alt=""></div>
      </div>
      <div class="infoItem">
        <div class="left">客服微信：{{ shopDetailConfig.wx }}</div>
        <div class="right" v-if="shopDetailConfig.wxLayout"><img :src="imgH + 'qrcodeIcon.png'" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
import { imgUrlHead } from '@/util/config.js';
export default {
  props: {
    shopDetailConfig: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
    }
  }
}
</script>

<style lang="less" scoped>
.shopDetail {
  padding: 14px;

  .content {
    padding: 15px;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px 10px 10px 10px;
    background-color: #fff;

    .bannerBox {
      position: relative;
      display: flex;
      margin-bottom: 20px;
      overflow: hidden;

      .imgBox {
        flex-shrink: 0;
        width: 100%;
        height: 160px;
        border-radius: 5px;
        overflow: hidden;

        img {
          height: 100% !important;
        }
      }

      .spotBox {
        position: absolute;
        bottom: 10px;
        left: 50%;
        display: flex;
        transform: translateX(-50%);
        z-index: 100;

        .spot {
          display: block;
          width: 16px;
          height: 5px;
          padding: 0;
          color: transparent;
          font-size: 0;
          background: rgba(0, 0, 0, 0.5);
          border: 0;
          border-radius: 1px;
          outline: none;
          cursor: pointer;
          margin-right: 5px;
          transition: all 0.5s;
        }

        .activeSpot {
          width: 24px;
          background: #333;
          opacity: 1;
        }
      }
    }

    .title_1 {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 20px;
    }

    .richBox {
      display: flex;
      flex-direction: column;
      margin-bottom: 22px;

      .top {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        padding: 6px 16px;
        margin: 0 auto;
        align-self: center;
        display: inline-block;
        border-bottom: 1px solid #333333;
        margin-bottom: 24px;
      }
    }

    .infoItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #333333;

      &:not(:last-child) {
        margin-bottom: 15px;
      }


      .left {
        width: calc(100% - 30px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .right {
        width: 24px;
        flex-shrink: 0;
        height: 24px;
      }
    }
  }
}
</style>