<template>
  <div class="videobig" :style="{ background: VideoConfig.bgColor }">
    <div class="videoBox">
      <div class="videoImg" v-if="status == 0">
        <img :src="VideoConfig.imgUrl == '' ? 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bigzheng.png' : VideoConfig.imgUrl" alt="" />
        <div class="videoPlay">
          <i class="iconfont icon-icon_play" @click="showVideo"></i>
        </div>
      </div>
      <div class="video" v-if="status == 1">
        <video controls muted autoplay loop :src="VideoConfig.videoUrl"></video>
      </div>
      <div class="videoTitle" v-if="status == 0 && VideoConfig.videoTitle.length != 0">
        {{ VideoConfig.videoTitle }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    VideoConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      status: 0
    }
  },
  methods: {
    changeStatus(val) {
      this.chooseImgFlag = val
    },
    showVideo() {
      if (this.VideoConfig.videoUrl == '') {
        this.$message({
          message: '暂未添加视频',
          type: 'error'
        })
        return
      }
      this.status = 1
    }
  }
}
</script>

<style lang="less" scoped>
.videobig {
  width: 100%;
  padding: 5px 0;
}
.videoBox {
  margin: 0 13px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  z-index: 100;
  .videoImg {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    .videoPlay {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #cdcdcd;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      i {
        font-size: 24px;
        color: #efeeec;
      }
    }
  }
  .videoTitle {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: rgba(175, 175, 176, 0.7);
    padding-left: 20px;
  }
}
.video {
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
  }
}
</style>
