<template>
  <div>
    <div class="set_s_card l">
      <div class="card_title">组件设置</div>
      <div class="set_item">
        <div>布局方式</div>
        <el-radio-group v-model="controlDefault.layout">
          <el-radio :label="0">方式一</el-radio>
          <el-radio :label="1">方式二</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>微信二维码</div>
        <el-radio-group v-model="controlDefault.wxLayout">
          <el-radio :label="0">不启用</el-radio>
          <el-radio :label="1">启用</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item flex_start" v-if="controlDefault.wxLayout">
        <div>选择图片</div>
        <div class="content">
          <div class="operationBox">
            <div class="imgBox">
              <img v-if="controlDefault.qrcode != ''" :src="controlDefault.qrcode" alt="" />
              <div class="upload" @click="chooseImg(-1)">选择图片</div>
            </div>
            <div class="del-l" @click="controlDefault.qrcode = ''">清除</div>
          </div>
        </div>
      </div>
      <div class="set_item">
        <div>导航地址</div>
        <el-radio-group v-model="controlDefault.navigationLayout">
          <el-radio :label="0">不启用</el-radio>
          <el-radio :label="1">启用</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item" v-if="controlDefault.navigationLayout">
        <div>经纬度</div>
        <div class="content">
          <el-input disabled v-model="controlDefault.lonLat.lon" style="margin-right:5px"></el-input>
          <el-input disabled v-model="controlDefault.lonLat.lat" style="margin-right:5px"></el-input>
          <div class="blue-s" @click="locationFlag = true">定位</div>
        </div>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">背景设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="controlDefault.bgColor = '#fff'">重置</div>
        </div>
      </div>
    </div>
    <div class="set_s_card">
      <div class="card_title">文字设置</div>
      <div class="set_item" v-if="!controlDefault.layout">
        <div>店名设置</div>
        <el-input v-model="controlDefault.shopName"></el-input>
      </div>
      <div class="set_item">
        <div>营业时间</div>
        <el-input v-model="controlDefault.businessHours"></el-input>
      </div>
      <div class="set_item">
        <div>服务地址</div>
        <el-input v-model="controlDefault.address"></el-input>
      </div>
      <div class="set_item">
        <div>联系电话</div>
        <el-input v-model="controlDefault.tel"></el-input>
      </div>
      <div class="set_item">
        <div>客服微信</div>
        <el-input v-model="controlDefault.wx"></el-input>
      </div>
    </div>
    <div class="set_s_card" v-if="controlDefault.layout">
      <div class="card_title">简介设置</div>
      <Rich :width="326" :height="300" class="rich" @soninfo="(val) => controlDefault.desc = val"
        :richTxt="controlDefault.desc"></Rich>
    </div>
    <div class="set_i_card" v-else>
      <div class="card_top">
        <div class="card_title">轮播图设置</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加banner图" placement="top-end">
            <img style="margin-right: 8px" @click="addBanner" :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.list" :key="index">
                <img :src="item" alt="" />
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <!--<div class="tips" style="margin-top: 8px">*建议尺寸686px*320px，上传图片不超过1M，支持 png、bmg、jpeg、jpg、gif</div>-->
      <vuedraggable v-model="controlDefault.list" v-bind="{
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scroll: true,
      }">
        <transition-group>
          <div class="chooseImage" v-for="(item, index) in controlDefault.list" :key="index">
            <div class="imgBox" id="imgBox">
              <img :src="item" alt="" />
              <div class="mask" @click="chooseImg(index)">选择图片</div>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
    <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag"
      @getSource="getImgRes"></UploadSource>
    <!-- 打开高德地图进行定位 -->
    <el-dialog title="定位" :visible.sync="locationFlag" width="1000px">
      <GaoDe v-if="locationFlag" :initialization="locationFlag" ref="searchGaode" class="searchGaode" id="searchId"
        :FunctionMode="2" :options="{ center: [controlDefault.lonLat.lon, controlDefault.lonLat.lat] }">
      </GaoDe>
      <span slot="footer" class="dialog-footer">
        <el-button @click="locationFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureSearch">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template> 

<script>
import { imgUrlHead } from '@/util/config.js';
import UploadSource from '@/components/uploadSource';
import Rich from '@/components/richText';
import vuedraggable from 'vuedraggable';
import GaoDe from '@/components/gaode.vue';
export default {
  components: {
    UploadSource,
    Rich,
    vuedraggable,
    GaoDe
  },
  props: {
    controlDefault: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      chooseImgFlag: false,
      imgIndex: -1,
      locationFlag: false
    };
  },
  methods: {
    addBanner () {
      if (this.controlDefault.list.length == 5) {
        this.$message({
          message: '最多只可以添加五张banner图哦~',
          type: 'error',
        });
        return;
      }
      this.controlDefault.list.push('https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bannerDefault.png');
    },
    // 删除对应banner图
    deleteItem (index) {
      if (this.controlDefault.list.length === 1) {
        this.$message.warning('至少保留一张banner');
        return;
      }
      this.controlDefault.list.splice(index, 1);
      this.$emit('getImg', this.controlDefault, { action: 'delete', index });
    },
    chooseImg (index) {
      this.imgIndex = index
      this.chooseImgFlag = true
    },
    changeStatus (val) {
      this.chooseImgFlag = val;
    },
    getImgRes (data) {
      if (this.imgIndex == -1) {
        this.controlDefault.qrcode = data[0].path
      } else {
        this.controlDefault.list[this.imgIndex] = data[0].path
      }
    },
    // 确认使用该坐标
    sureSearch () {
      let { defaultSearch, defaultCenter, simple_address } = this.$refs.searchGaode;
      this.controlDefault.lonLat.lon = defaultCenter[0];
      this.controlDefault.lonLat.lat = defaultCenter[1];
      this.locationFlag = false;
    },
  }
}
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);

.searchGaode /deep/ img {
  width: 50px !important;
  height: 50px !important;
}

#imgBox {
  width: 100% !important;
  position: relative;

  &:hover .mask {
    opacity: 1;
  }

  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.21);
    user-select: none;
    cursor: pointer;
  }
}

.operationBox {
  width: 100%;
  display: flex;
  align-items: flex-end;

  .imgBox {
    width: 88px;
    height: 88px;
    background-color: #fff;
    border-radius: 5px;
    margin-right: 16px;
    .flex();

    img {
      width: 80px !important;
      height: 80px !important;
    }

    position: relative;

    .upload {
      cursor: pointer;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 25px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0px 0px 5px 5px;
      .title(14px, #fff, 400);
      .flex();
    }
  }
}
</style>