<template>
    <div class="bannerControl">
        <div class="set_s_card">
            <div class="card_title">背景设置</div>
            <div class="set_item">
                <div>选择边距</div>
                <el-radio-group v-model="controlDefault.chooseMargin">
                    <el-radio :label="1">左右边距</el-radio>
                    <el-radio :label="0">无边距</el-radio>
                </el-radio-group>
            </div>
            <div class="set_item">
                <div>背景颜色</div>
                <div class="content">
                    <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
                    <div class="blue-l" @click="returnbgColor">重置</div>
                </div>
            </div>
        </div>

        <div class="set_i_card">
            <div class="card_top">
                <div class="card_title">到店付款设置</div>
            </div>
            <div class="tips" style="margin-top: 8px">*建议尺寸686px*320px，上传图片不超过1M，支持 png、bmg、jpeg、jpg、gif</div>
            <div class="chooseImage">
                <div class="imgBox">
                    <img :src="controlDefault.imgURL" alt="" />
                </div>
                <div class="btnArea" style="justify-content: center;">
                    <div class="blue-b" @click="chooseImgFlag = true;">选择图片</div>
                </div>
            </div>
        </div>
        <!-- 选择图片弹框 -->
        <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag"
            @getSource="getImgRes" :maxSize="1024 * 1024"></UploadSource>
    </div>
</template>
  
<script>
import UploadSource from '@/components/uploadSource';
import { imgUrlHead } from '@/util/config.js';
export default {
    props: {
        controlDefault: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        UploadSource,
    },
    data () {
        return {
            imgH: imgUrlHead + 'diyComponentsImg/',

            toPageFlag: false,
            chooseImgFlag: false,
            itemIndex: -1,
        };
    },
    updated () {
        this.$emit('getImg', this.controlDefault);
    },
    methods: {
        changeStatus (val) {
            this.chooseImgFlag = val;
        },
        returnbgColor () {
            this.controlDefault.bgColor = null;
        },
        // 拿到图片
        getImgRes (imglist) {
            this.controlDefault.imgURL = imglist[0].path;
        },
    },
};
</script>
  
<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
  