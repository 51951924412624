<template>
  <div class="btnControl">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
    </div>

    <div class="set_i_card">
      <div class="card_top">
        <div class="card_title">图片样式设置</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加按钮" placement="top-end">
            <img style="margin-right: 8px" @click="addBtn" :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.list" :key="index">
                <img :src="item.imgUrl" alt="" />
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <div class="tips" style="margin-top: 8px">*建议图片尺寸96px*96px</div>
      <div class="set_item flex_start">
        <div>每行数量</div>
        <el-radio-group class="noWarp" @change="changeLayout" v-model="controlDefault.layout">
          <el-radio :label="3">3个</el-radio>
          <el-radio :label="4">4个</el-radio>
          <el-radio :label="5">5个</el-radio>
        </el-radio-group>
      </div>
      <vuedraggable
        v-model="controlDefault.list"
        v-bind="{
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost',
          scroll: true,
        }"
      >
        <transition-group>
          <div class="chooseBtn" v-for="(item, index) in controlDefault.list" :key="index">
            <div class="imageBox">
              <img v-if="item.imgUrl" :src="item.imgUrl" alt="" />
              <div class="upload" @click="chooseImg(index)">选择图片</div>
            </div>
            <div class="btnConfig">
              <div>
                <el-input v-model="item.name"></el-input>
                <div class="blue-b" @click="item.name = ''">清空</div>
              </div>
              <div>
                <el-input v-model="item.toPage" disabled></el-input>
                <div class="blue-b" @click="toPageShow(index)">选择</div>
              </div>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
    <!-- 图片上传弹框 -->
    <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag" @getSource="getImgRes"></UploadSource>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import ToPage from '@/views/addPages/toPage/index.vue';
import UploadSource from '@/components/uploadSource';
import { imgUrlHead } from '@/util/config.js';
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      toPageFlag: false,
      itemIndex: -1,
      chooseImgFlag: false,
      checkLayout: null,
    };
  },
  components: {
    vuedraggable,
    ToPage,
    UploadSource,
  },
  methods: {
    changeStatus(val) {
      this.chooseImgFlag = val;
    },
    // 重置颜色
    returnColor() {
      this.controlDefault.bgColor = null;
    },

    // 删除按钮
    deleteItem(index) {
      if (this.controlDefault.list.length == 2) {
        this.$message({
          message: '按钮数量不可小于2个',
          type: 'warning',
        });
        return;
      }
      this.controlDefault.list.splice(index, 1);
      this.$emit('sonMethod', this.controlDefault, { action: 'delete', index });
    },
    // 开启选择链接弹框
    toPageShow(index) {
      this.toPageFlag = true;
      this.itemIndex = index;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes(item) {
      this.controlDefault.list[this.itemIndex].toPage = item.wxroute;
      this.toPageFlag = false;
    },
    // 选择图片
    chooseImg(index) {
      this.itemIndex = index;
      this.chooseImgFlag = !this.chooseImgFlag;
    },
    // 添加按钮组
    addBtn() {
      if (this.controlDefault.layout == this.controlDefault.list.length) {
        this.$message({
          message: '按钮数量不可大于所勾选的每行数量',
          type: 'warning',
        });
        return;
      }
      this.controlDefault.list.push({
        name: '按钮文字',
        imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
        toPage: '跳转页面',
      });
    },
    // 拿到图片
    getImgRes(imglist) {
      this.controlDefault.list[this.itemIndex].imgUrl = imglist[0].path;
    },
    changeLayout(index) {
      if (index < this.controlDefault.list.length) {
        this.$message({
          message: '每行数量不可小于按钮数量',
          type: 'warning',
        });
        this.controlDefault.layout = this.checkLayout;
      } else {
        this.checkLayout = index;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
