<template>
  <div class="collageControl">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnBgColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>商品色系</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.goodsColorSystem"></el-color-picker>
          <div class="blue-l" @click="returnGoodsColorSystem">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>拼团色系</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.collageColorSystem"></el-color-picker>
          <div class="blue-l" @click="returnCollageColorSystem">重置</div>
        </div>
      </div>
    </div>

    <div class="set_i_card">
      <div class="card_top" style="margin-bottom: 24px">
        <div class="card_title">商品设置</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加商品" placement="top-end">
            <img style="margin-right: 8px" @click="addItem" :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.list" :key="index">
                <img :src="item.picture" alt="" />
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <div class="set_item flex_start">
        <div>布局方式</div>
        <el-radio-group v-model="controlDefault.layout">
          <el-radio :label="0">双列显示</el-radio>
          <el-radio :label="1">单行滚动</el-radio>
          <el-radio :label="2">列表显示</el-radio>
        </el-radio-group>
      </div>
      <vuedraggable v-model="controlDefault.list" v-bind="{
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scroll: true,
      }">
        <transition-group>
          <div class="chooseImage" id="chooseImage" v-for="(item, index) in controlDefault.list" :key="index">
            <div class="imgBox">
              <img :src="item.picture" alt="" />
              <div class="upload" @click="chooseGoods(index)">选择商品</div>
            </div>
            <div class="btnArea">
              <el-input v-model="item.goods_name" disabled placeholder="商品名称"></el-input>
              <el-input v-model="item.group_price" disabled placeholder="拼团价格"></el-input>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes" type="collage"></ToPage>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import ToPage from '@/views/addPages/toPage/index.vue';
import getDate from '@/util/getDate.js';
import { imgUrlHead } from '@/util/config.js';
export default {
  components: {
    vuedraggable,
    ToPage,
  },
  props: {
    controlDefault: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      toPageFlag: false,
      chooseIndex: -1,
    };
  },
  methods: {
    returnBgColor () {
      this.controlDefault.bgColor = null;
    },
    returnGoodsColorSystem () {
      this.controlDefault.goodsColorSystem = '#333333';
    },
    returnCollageColorSystem () {
      this.controlDefault.collageColorSystem = '#FF5353';
    },
    // 点击新增商品
    addItem () {
      this.controlDefault.list.push({
        goods_picture: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/620zheng.png',
        goods_name: '商品名称',
        goods_price: '0.01',
      });
    },
    // 删除商品
    deleteItem (index) {
      if (this.controlDefault.list.length <= 1) {
        this.$message.warning('至少设置一个拼团!!');
        return;
      }
      this.controlDefault.list.splice(index, 1);
    },
    chooseGoods (index) {
      this.chooseIndex = index;
      this.toPageFlag = true;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
        this.$refs.toPage.chooseIndex('groupGoodsDetail');
      });
    },
    // 获取选中的商品
    getPageRes (item) {
      let nowTime = Date.now();
      let start = item.start_time * 1000;
      let end = item.end_time * 1000;
      if (start < nowTime) {
        item.isStart = true;
        let surplus_time = end - nowTime;
        let day = Math.floor(surplus_time / 1000 / 60 / 60 / 24);
        if (day < 10) day = `0${day}`;
        let hour = Math.floor((surplus_time / 1000 / 60 / 60) % 24);
        if (hour < 10) hour = `0${hour}`;
        let minutes = Math.floor((surplus_time / 1000 / 60) % 60);
        if (minutes < 10) minutes = `0${minutes}`;
        let seconds = Math.floor((surplus_time / 1000) % 60);
        if (seconds < 10) seconds = `0${seconds}`;
        item.day = day;
        item.hour = hour;
        item.minutes = minutes;
        item.seconds = seconds;
      } else {
        item.isStart = false;
        let { month, day, hour, minutes } = getDate.getDate(start);
        item.month = month;
        item.day = day;
        item.hour = hour;
        item.minutes = minutes;
      }
      if (Number(item.group_price) >= 1000 && String(item.group_price).length >= 7) {
        item.group_price = String(item.group_price).substring(0, 6);
      }
      this.$set(this.controlDefault.list, this.chooseIndex, item);
      this.toPageFlag = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
