<template>
  <div class="richBox">
    <div class="set_s_card">
      <div class="card_title">颜色设置</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnColor">重置</div>
        </div>
      </div>
    </div>
    <Rich :width="360" :height="500" class="rich" @soninfo="richContant" :richTxt="controlDefault.richTxt"></Rich>
  </div>
</template>

<script>
import Rich from '@/components/richText';
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Rich,
  },
  methods: {
    // 重置颜色
    returnColor() {
      this.controlDefault.bgColor = null;
    },
    richContant(val) {
      this.controlDefault.richTxt = val;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
.rich {
  margin-top: 16px;
}
</style>
