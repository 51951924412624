<template>
  <div class="gaode">
    <div v-if="FunctionMode == 2 || FunctionMode == 3" class="topSearch">
      <el-autocomplete prefix-icon="el-icon-search" :trigger-on-focus="false" v-model="defaultSearch"
        :fetch-suggestions="querySearchAsync" placeholder="请输入内容" @select="handleSelect" value-key="name">
      </el-autocomplete>
      <div class="showCenter">选中地点的经纬度：{{ defaultCenter[0] }}，{{ defaultCenter[1] }}</div>
    </div>
    <div class="btnGroup" v-if="FunctionMode == 1 && circularOrline === 1">
      <el-button type="primary" size="small" v-if="isClose" @click="againTag">重新标记</el-button>
      <el-button type="primary" size="small" v-if="!isClose" @click="isOpenTag = !isOpenTag">{{ !isOpenTag ? '开始标记' :
          '暂停标记'
      }}</el-button>
      <el-button type="primary" size="small" v-if="polylines.length && !isClose" @click="removeLine">撤回</el-button>
      <el-button type="primary" size="small" v-if="tagList.length > 2 && !isClose" @click="closeTag">完成</el-button>
    </div>
    <div class="btnGroup inputRadius" v-if="FunctionMode == 1 && circularOrline === 0 && mapIsCompele">
      <div>配送区域：</div>
      <div>
        <el-input v-model="circularRadius"></el-input>
        公里
      </div>
    </div>
    <div :class="[FunctionMode === 1 ? 'map1' : 'map2']" :id="id"></div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import _ from 'lodash';
import { imgUrlHead } from '@/util/config.js';
export default {
  props: {
    id: {
      type: String,
      default: 'container',
    },
    // 配置项 initialization 监听中查看配置项
    options: {
      type: Object,
      default: () => { },
    },
    // 必传 为true 时初始化地图
    initialization: {
      type: Boolean,
      default: false,
    },
    // 功能模式  1-只能标记地图 2-只有搜索
    FunctionMode: {
      type: Number || String,
      default: 1,
    },
    // 标记方式为圆还是折线 0-圆 1-折线
    circularOrline: {
      type: Number || String,
      default: 0,
    },
  },

  data () {
    return {
      // 图片请求头
      imgUrlHead,
      // 地图是否加载完成
      mapIsCompele: false,
      // 默认地图中心点 -> 也为圆心坐标
      defaultCenter: [116.397451, 39.909187],
      // 中心点对象
      centerTag: null,
      // 默认搜索字段
      defaultSearch: '',
      // 不拼接字段
      simple_address: '',
      // 是否开始标记配送区
      isOpenTag: false,
      // 是否闭合范围
      isClose: false,
      // 搜索时显示加载中
      loading: false,
      // 搜索时使用的key
      searchKey: '26232986d00397c2ee389c6451f3a717',
      // 最终选择关键字信息
      chooseThatKeyword: {},
      // 关键字搜索的地名集合
      keywordList: [],
      // 高德对象
      AMap: null,
      // 地图对象
      map: null,
      // 收集折线上的点的经纬度
      lngLatList: [],
      // 生成的折线列表
      polylines: [],
      // 收集生成的点对象
      tagList: [],
      // 当前折线经纬度
      nowLngLat: [],
      // 存储圆形集合
      circularList: [],
      // 圆的半径 单位公里 1公里 = 1km
      circularRadius: 1,
    };
  },
  mounted () {
    this.createdGaoDe();
  },
  watch: {
    // 检测中心点是否发生改变
    defaultCenter: {
      handler (val) {
        if (this.FunctionMode === 2) return;
        if (!this.mapIsCompele) return;
        //  当经纬度发生改变时 更改地图默认点
        if (this.circularOrline === 0) {
          // 更改地图上面的原型标记
          this.changeCircle();
        } else if (this.circularOrline === 1) {
          this.tagAndCenter();
        }
      },
      deep: true,
    },
    // 自动以两点生成折线
    nowLngLat: {
      handler (val) {
        this.successFnLine();
      },
      immediate: true,
    },
    // 检查地图是否需要配置初始数据
    initialization: {
      handler (val) {
        if (val) {
          // 当勾选方式为圆形时 center即为圆心
          if (this.options.center && this.options.center[0]) {
            // 如果有传入中心点 并且有经纬度
            this.defaultCenter = this.options.center; // 配置默认中心点
          }
          if (this.options.keyword) {
            this.defaultSearch = this.options.keyword;
          }
        }
      },
      immediate: true,
    },
    // 切换选择范围方式
    circularOrline (val) {
      if (!this.mapIsCompele) return;
      if (val === 0) {
        // 切换成按半径形态 清除所有折线但是保留数据
        this.polylines.map(item => {
          this.map.remove(item);
        });
        this.tagList.map(item => {
          this.map.remove(item);
        });
        // 生成保留的圆形标记
        this.changeCircle();
      } else if (val === 1) {
        // 切换成自定义 清除所有的圆形标记 circularList
        this.circularList.map(item => {
          this.map.remove(item);
        });
        // 生成点和折线
        this.polylines.map(item => {
          this.map.add(item);
        });
        this.tagList.map(item => {
          this.map.add(item);
        });
      }
    },
    // 该变圆的半径时更新视图
    circularRadius () {
      if (!this.mapIsCompele) return;
      this.changeCircle();
    },
  },
  methods: {
    // 条件满足时 创建折线
    successFnLine () {
      if (this.nowLngLat.length == 2) {
        // 此时生成折线 并渲染到地图
        let Polyline = this.createLine();
        this.map.add(Polyline);
        // 将折线加入折线列表
        this.polylines.push(Polyline);
        // 调整生成折线的数组
        this.nowLngLat = [this.nowLngLat[1]];
      }
    },
    // 编辑时 传入初始值 并渲染
    edieInit (is_diy, data) {
      // 创建 并定位到 中心点
      if (is_diy === 0) {
        this.circularRadius = data;
      } else if (is_diy === 1) {
        this.lngLatList = data;
      }
    },
    // 26232986d00397c2ee389c6451f3a717 位置搜索服务
    // 5ab19bee1ccae980aff457ac019cc330 编辑地图服务
    createdGaoDe () {
      AMapLoader.load({
        key: '5ab19bee1ccae980aff457ac019cc330', // 申请好的Web端开发者Key，首次调用 load 时必填
        // version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.MouseTool', 'AMap.PolylineEditor'], // 需要使用的的插件列表
      })
        .then(AMap => {
          this.AMap = AMap;
          this.map = new AMap.Map(this.id, {
            //设置地图容器id
            center: this.defaultCenter, //初始化地图中心点位置
            zoom: this.initialization ? 16 : 14,
            resizeEnable: true,
          });
          let that = this;
          // 处理初始搜索状态
          if (this.FunctionMode == 2) {
            this.tagAndCenter();
          }

          // 注册一个地图的点击事件
          this.map.on('click', function (ev) {
            if (that.isOpenTag) {
              // 点击收集经纬度并生成占位符
              let { lng, lat } = ev.lnglat;
              // 收集该点的经纬度
              that.lngLatList.push([lng, lat]);
              // 将该点push到折线生成数组中
              that.nowLngLat.push(new AMap.LngLat(lng, lat));
              // 创建该点对象并渲染
              let tag = that.createTag([lng, lat]);
              that.map.add(tag);
              that.tagList.push(tag);
            }
          });
          // 检测地图是否加载完成
          this.map.on('complete', function () {
            // 地图图块加载完成后触发
            that.mapIsCompele = true;
            if (that.FunctionMode === 2) return;
            if (that.circularOrline === 0) {
              that.changeCircle();
            } else if (that.circularRadius === 1) {
              that.tagAndCenter();
              // 初始化点集合
              that.lngLatList.map((item, index) => {
                let tag = that.createTag(item);
                that.map.add(tag);
                that.tagList.push(tag);
                that.nowLngLat.push(new that.AMap.LngLat(item[0], item[1]));
                that.successFnLine();
              });
              that.closeTag();
            }
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    // 创建一根直线 line
    createLine () {
      // 创建折线实例
      let polyline = new this.AMap.Polyline({
        path: _.cloneDeep(this.nowLngLat),
        borderWeight: 2, // 线条宽度，默认为 1
        strokeColor: 'red', // 线条颜色
        lineJoin: 'round', // 折线拐点连接处样式
      });
      return polyline;
    },
    // 创建多边形的标记点
    createTag (lngLat, icon = 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png') {
      let marker = new this.AMap.Marker({
        position: new this.AMap.LngLat(lngLat[0], lngLat[1]),
        offset: new this.AMap.Pixel(-10, -31),
        icon,
      });
      return marker;
    },
    createCenter (lngLat, icon = 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png') {
      let marker = new this.AMap.Marker({
        position: new this.AMap.LngLat(lngLat[0], lngLat[1]),
        offset: new this.AMap.Pixel(-25, -25),
        icon,
        zoom: 13,
      });
      return marker;
    },
    //将中心点渲染进地图 生成点 并将其定位到地图中心
    tagAndCenter () {
      if (this.centerTag) {
        this.map.remove(this.centerTag);
        this.centerTag = null;
      }
      // 创建标记点
      this.centerTag = this.createCenter(this.defaultCenter, imgUrlHead + 'mapCenter.png');
      // 将新生成的标记点添加进地图
      this.map.add(this.centerTag);
      // 设置地图新的中心点
      this.map.setZoomAndCenter(15, this.defaultCenter);
    },
    // 创建圆形实例
    createCircle (fillColor = '#b5c6ea') {
      let circle = new this.AMap.Circle({
        center: new this.AMap.LngLat(this.defaultCenter[0], this.defaultCenter[1]), // 圆心位置
        radius: this.circularRadius * 1000, // 圆半径
        fillColor: fillColor || '#b5c6ea', // 圆形填充颜色
        fillOpacity: 0.3,
        strokeColor: '#409eff', // 描边颜色
        strokeWeight: 2, // 描边宽度
      });
      return circle;
    },
    // 对高德地图发起请求
    sendRequest (url, callback = () => { }) {
      let ajax = new XMLHttpRequest();
      ajax.open('get', url);
      //步骤三:发送请求
      ajax.send();
      //步骤四:注册事件 onreadystatechange 状态改变就会调用
      ajax.onreadystatechange = function () {
        if (ajax.readyState == 4 && ajax.status == 200) {
          //步骤五 如果能够进到这个判断 说明 数据 完美的回来了,并且请求的页面是存在的
          callback(JSON.parse(ajax.responseText));
        }
      };
    },
    // 获取建议项并且渲染
    querySearchAsync (queryString, cb) {
      let that = this;
      let url = `https://restapi.amap.com/v3/assistant/inputtips?keywords=${queryString}&key=${this.searchKey}`;
      this.sendRequest(url, data => (that.keywordList = data.tips));
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(that.keywordList);
      }, 1000 * Math.random());
    },
    // 选中建议项
    handleSelect (data) {
      // 存储该建议项
      this.chooseThatKeyword = data;
      // 判断是否是选中行政区
      if (Array.isArray(data.id)) {
        this.defaultSearch = data.district + data.address;
        this.$message.warning('请选择精确地址');
        return;
      } else {
        this.defaultSearch = data.district + data.address + data.name;
        this.simple_address = data.name;
        // 拆解经纬度
        this.defaultCenter = data.location.split(',');
      }
      this.tagAndCenter();
    },

    // 移除折线列表最后一根折线
    removeLine () {
      this.$confirm('此操作将清除最后生成的一根折线, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log(this.isClose, '是否已经闭合');
          // 删除折线 并清除末尾的点 以及点的经纬度
          this.clearLastPolyline();
          this.clearLastTag();
          if (!this.polylines.length) {
            // 清除最后一个点的数据
            this.clearLastTag();
            this.nowLngLat = [];
          }
          // 如果地图上还有未清除的折线点
          if (this.lngLatList.length) {
            // 将该点push到折线生成数组中
            this.startTag();
          }
        })
        .catch(() => { });
    },
    // 清除最后一个折线
    clearLastPolyline () {
      this.map.remove(this.polylines[this.polylines.length - 1]);
      this.polylines.splice(this.polylines.length - 1, 1);
    },
    // 清除最后一个标记点
    clearLastTag () {
      this.map.remove(this.tagList[this.tagList.length - 1]);
      this.lngLatList.splice(this.lngLatList.length - 1, 1);
      this.tagList.splice(this.tagList.length - 1, 1);
    },
    // 将标记点列表的最后一个点设置成下一条折线起点
    startTag () {
      this.nowLngLat = [new this.AMap.LngLat(this.lngLatList[this.lngLatList.length - 1][0], this.lngLatList[this.lngLatList.length - 1][1])];
    },
    // 闭合范围
    closeTag () {
      // 将最后一个点与第一个点进行连接
      this.nowLngLat.push(new this.AMap.LngLat(this.lngLatList[0][0], this.lngLatList[0][1]));
      // 闭合范围 并自动关闭标记功能
      this.isClose = true;
      this.isOpenTag = false;
    },
    // 更改圆形
    changeCircle (color) {
      let circle = this.createCircle(color);
      if (this.circularList.length) {
        this.map.remove(this.circularList[0]);
        this.circularList[0] = circle;
      }
      this.map.add(circle);
      this.circularList.push(circle);
      this.tagAndCenter(this.defaultCenter);
    },

    // 重新开始标记
    againTag () {
      this.polylines.map(item => {
        this.map.remove(item);
      });
      this.tagList.map(item => {
        this.map.remove(item);
      });
      this.polylines = [];
      this.tagList = [];
      this.lngLatList = [];
      this.nowLngLat = [];
      this.isOpenTag = false;
      this.isClose = false;
    },
  },
};
</script>

<style lang="less" scoped>
.gaode {
  position: relative;

  .btnGroup {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 20;
  }

  .inputRadius {
    width: 180px;
    height: 130px;
    background-color: #fff;
    border: 2px solid #409eff;
    padding: 10px 20px;

    .el-input {
      width: 100px;
    }
  }

  .topSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .el-autocomplete {
    width: 400px;
  }
}

.map1 {
  width: 100%;
  height: 700px;
}

.map2 {
  width: 100%;
  height: 500px;
}
</style>
